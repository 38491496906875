<template>
  <transition name="fade">
    <CCard class="chart-card chart-card--fuchsia">
      <CCardHeader>
        <h5 class="my-2" v-translate>Engagement</h5>
      </CCardHeader>
      <CCardBody class="pb-0">
        <Metrics
            :configuration="metricsConfiguration"
            :date_to="date_to"
            :date_from="date_from"
            :time_zone="time_zone"
            :need_date="need_date"
        />
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import Metrics from "@/domain/eventMetric/Metrics.vue";
import {mapGetters} from "vuex";

export default {
  name: "MetricCard",
  components: {Metrics},
  props: {
    date_from: {
      type: [Date, null],
      required: true
    },
    date_to: {
      type: [Date, null],
      required: true
    },
    need_date: {
      type: Boolean,
      required: true
    },
    time_zone: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters('eventMetric', {
      metricsConfiguration: 'configuration'
    }),
  }
}
</script>


<style scoped>

</style>