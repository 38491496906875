<template>

  <CRow>
    <CCol sm="12" >
      <div style="border-bottom: 1px solid #dedede">
      <div class="d-flex justify-content-between m-3">
        <div class="mr-5">
          <span class="font-weight-semi-bold mr-2 " v-translate>Total orders</span>
          <h6 class="d-inline text-highlighted">
            {{ ordersCount | number }}</h6>
        </div>
        <div>
          <div>
            <span class="font-weight-semi-bold mr-2" v-translate>Total amount</span>
            <h6 class="d-inline text-highlighted">
              {{ totalAmount|currency(currency) }}
            </h6>
          </div>
        </div>
      </div>
        </div>
    </CCol>
    <CCol>
      <CListGroup flush class="list-striped" v-if="items">
        <CListGroupItem v-for="(item, key) in items"
                        class="flex-column align-items-start border-bottom-0"
                        :key="key">
          <div class="d-flex w-100 justify-content-between">
            <span class="font-weight-bold">{{ item.label }}</span>
            <div>
              <div class="text-right">
                <span class="ml-2"><translate>Orders</translate>: </span>
                <span>{{ item.order_item_count|number }} ({{ getPercentage(ordersCount, item.order_item_count)|number }}%)</span>

              </div>
              <div class="text-right">
                <span class="ml-2"><translate>Upsell</translate>: </span>
                <span>{{ item.upsell_count|number }}</span>
              </div>
              <div class="text-right">
                <span v-if="totalAmount" class="legend__amount">{{ item.amount|currency(currency) }} ({{getPercentage(totalAmount, item.amount)|number}}%)</span>
              </div>
            </div>
          </div>
        </CListGroupItem>
      </CListGroup>
    </CCol>
  </CRow>
</template>

<script>


import {getPercentage} from "@/utils/functions";

export default {
  name: "OrdersCampaigns",
  methods: {getPercentage},
  props: {
    data: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    totalAmount() {
      return this.data.amount;
    },
    ordersCount() {
      return this.data.order_item_count;
    },
    items() {
      return this.data.by_campaign
    },

  }

}
</script>

<style scoped>

</style>