<template>
  <div>
    <ViewLoadingMessage :loading="loading" class="pb-5"/>
    <template v-if="!loading">
      <CDataTable
          :striped="true"
          :items="releasesItems"
          :fields="releasesDataFields"
          class="custom-table custom-table--clean custom-table--border-top mb-4">
        <template #no-items-view>
          <div class="text-center">
            <h6 v-translate>No data</h6>
          </div>
        </template>
        <template #release_id="{item}">
          <td>
            <div>
              {{ item.description }}
            </div>
            <div>
              {{ item.race_number_from }} - {{ item.race_number_to }}
            </div>
          </td>
        </template>
        <template #price_list_name="{ item }">
          <td>
            <CBadge v-if="!item.price_list_name" class=" badge-outline" color="danger">
              <translate>NOT SET</translate>
            </CBadge>
            <CBadge v-else-if="item.price_list_name === 'free'" color="info" class=" badge-outline">
              {{ item.price_list_name | upper }}
            </CBadge>
            <CBadge v-else color="success" class="badge-outline">{{ item.price_list_name | upper }}</CBadge>
          </td>
        </template>
         <template #checkin_quantity="{item}">
          <td>
            <div><span v-translate>Recognized participants</span> <span class="font-weight-semi-bold">{{ item.recognized_participants|number }}</span></div>
            <div><span v-translate>Check-ins</span> <span class="font-weight-semi-bold">{{ item.release_checkin_quantity|number }}</span></div>
<!--            <div class="font-weight-semi-bold text-primary" style="font-size: 12px">
              <template v-if="item.release_checkin_fraction">
                {{item.release_checkin_fraction*100|number }}%
              </template>
              <template v-if="item.photographer_checkin_fraction">
                {{item.photographer_checkin_fraction*100|number }}%
              </template>

            </div>-->
          </td>
        </template>
        <template #recognized_participants="{item}">
          <td>
            {{getPercentage(item.recognized_participants,item.release_checkin_quantity)|number}}%
          </td>
        </template>

        <template #order_conversion_rate="{item}">
          <td>
            {{ (item.order_conversion_rate * 100)|number }}%
          </td>
        </template>
        <template #order_count="{item}">
          <td>
            {{item.order_count|number}}
          </td>
        </template>
        <template #order_amount="{item}">
          <td>
            <span class="font-weight-semi-bold">{{ item.order_amount|currency(currency) }}</span>
          </td>
        </template>
      </CDataTable>
    </template>
  </div>
</template>

<script>


import {releasesDataFields} from "@/views_pica/stats/releases/constans";
import {getPercentage} from "@/utils/functions";
import StatsService from "@/services/stats.service";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReleasesDataTable",
  components: {ViewLoadingMessage},

  data() {
    return {
      loading: true,
      data: null
    }
  },
  props: {
    picaServiceId: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: false
    },
    photographerId: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters('stats', [
      'releases'
    ]),
    releasesItems() {
      return this.releases.releases
    },
    totalCheckins() {
      return this.releasesItems.reduce((accumulator, currentValue) => accumulator + currentValue.release_checkin_quantity, 0);
    },
    releasesDataFields() {
      return Object.values(releasesDataFields)
    }
  },

  async mounted() {
    await this.getReleases({
      picaServiceId: this.picaServiceId,
      params: {
        photographer_id: this.photographerId
      }
    })
    this.loading = false
  },
  methods: {
    ...mapActions('stats', [
      'getReleases'
    ]),
    getPercentage
  },

}
</script>


