<template>
<transition name="fade">
        <CCard class="chart-card chart-card--orange" v-show="showPhotographicProduction && showPhotographicProductionTable">
          <CCardHeader>
            <h5 class="my-2" v-translate>Uploads</h5>
          </CCardHeader>
          <CCardBody class="pb-0">

            <PhotographicProductionDataTable ref="photographicProductionDataTable"
                                             :is-multi-events="isMultiEvents"
                                             :get-params="getParams"
                                             :get-body-params="getBodyParams"
                                             :photographic-production-summary="photographicProductionSummary"
                                             :selected-period="currentPeriod"
                                             :show-imago-data="showImagoData"
                                             @canView="onCanView"/>
          </CCardBody>
        </CCard>
      </transition>
</template>

<script>
import PhotographicProductionDataTable
  from "@/views_pica/stats/photographicProduction/PhotographicProductionDataTable.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PhotographicProductionCard",
  components: {PhotographicProductionDataTable},
  data() {
    return {
      showPhotographicProduction: false
    }
  },
  props: {
    picaServiceId: {
      type: Number,
      required: false
    },
    isMultiEvents: {
      type: Boolean,
      default: false
    },
    getParams: {
      type: Function,
      required: true
    },
    getBodyParams: {
      type: Function,
      required: true
    },
    currentPeriod: {
      type: Object,
      required: true
    },
    showImagoData: {
      type: Boolean,
      required: false
    },
    showPhotographicProductionTable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters('stats', ['photographicProductionSummary'])
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('stats', ['getPhotographicProductionSummary']),
    onCanView() {
      this.showPhotographicProduction = true
      this.$emit('canView')
    },
    async getData(){
       await this.getPhotographicProductionSummary({
        picaServiceId: this.picaServiceId,
        params: this.getParams(this.currentPeriod.suffix)
      })
    }
  }
}
</script>



<style scoped>

</style>