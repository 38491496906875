<template>
  <div class="d-flex h-100 align-items-center justify-content-around">
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <div class="text-center" v-if="!loading">
      <h2 v-translate class="mb-4">Leads</h2>
      <h3 style="font-size: 76px; color: #b24b14">{{ total|number }}</h3>
    </div>
  </div>
</template>

<script>
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";

export default {
  name: 'TotalLeadAcquired',
  components: {ViewLoadingMessage},
  props: {
    lead_acquired: {
      type: Object,
      required: false,
    },
  },
  computed: {
    loading() {
      return !this.lead_acquired
    },
    total() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.lead_acquired)
    }
  }
}
</script>
