<template>
  <div class="kpi" :class="['kpi--' + palette.name]">
    <ViewLoadingMessage extra-class="mt-5" :loading="loading" :size="`sm`"/>
    <WidgetKpi :heading="$gettext('Content promoters')"
                            :heading-icon="`cipVoice`"
                            :palette="palette"
                            :tooltip="tooltipContent.contentPromotersPercentage"
                            :heading-value="percent_active" v-if="!loading">
      <CListGroup flush>
        <CListGroupItem
            class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex align-items-start">
              <span v-translate>Unique content promoters</span>
              <PTooltip :content="tooltipContent.contentPromoters" :color="tooltipColor" />
            </div>

            <span class="font-weight-bold">{{ activeUsers.actual|number }}</span>
          </div>
        </CListGroupItem>
        <CListGroupItem
            class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex align-items-start">
              <span v-translate>Shared media</span>
              <PTooltip :content="tooltipContent.sharedMedia" :color="tooltipColor" />
            </div>
            <span class="font-weight-bold">{{ media_shared|number }}</span>
          </div>
        </CListGroupItem>
      </CListGroup>
    </WidgetKpi>
  </div>
</template>

<script>
import WidgetKpi from "@/views_pica/stats/components/WidgetKpi.vue";
import {mapGetters} from "vuex";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import {METRIC_TYPES} from "@/domain/eventMetric/consts";
import {tooltipContent, tooltipColor} from "@/domain/eventMetric/tooltipContent";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import Palette from "@/domain/core/utils/chart/Palette";

export default {
  name: 'EventBasicKpi',
  components: {PTooltip, ViewLoadingMessage, WidgetKpi},
  computed: {
    tooltipContent() {
      return tooltipContent
    },
    tooltipColor() {
      return tooltipColor
    },
    palette() {
      return {
        name: 'fuchsia',
        color: Palette.fuchsia[0]
      }
    },
    loading() {
      return !this.activeUsers.actual
          || !this.groupByType[METRIC_TYPES.SHARED_LINK_CREATED]
          || !this.groupByType[METRIC_TYPES.MEDIA_VIEWED_PHOTO]
          || !this.groupByType[METRIC_TYPES.MEDIA_VIEWED_VIDEO]
          || !this.groupByType[METRIC_TYPES.MEDIA_DOWNLOADED]
    },
    ...mapGetters('eventMetric', [
      'activeUsers',
      'groupByType'
    ]),
    media_shared() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.groupByType[METRIC_TYPES.SHARED_LINK_CREATED]) + totalHelper.getTotal(this.groupByType[METRIC_TYPES.MEDIA_DOWNLOADED])
    },
    percent_active() {
      if (this.activeUsers['potential'] <= 0) return 0
      const percent = this.activeUsers['actual'] / this.activeUsers['potential']
      return `${this.$options.filters.number(percent * 100)}%`
    }
  }
}
</script>
