<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <Bar
        v-if="!loading"
        :chart-data="chartData"
        :chart-options="chartData.options"
    />
  </div>
</template>

<script>
import ChartHelper from "@/domain/core/utils/chart/ChartHelper";
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import Palette from "@/domain/core/utils/chart/Palette";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import ChartOptions from "@/domain/core/utils/chart/ChartOptions";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'LeadAcquiredByPeriod',
  components: {ViewLoadingMessage, Bar},
  props: {
    lead_acquired: {
      type: Object,
      required: false,
    },
    label_format: {
      type: String,
      required: false,
    }
  },
  computed: {
    loading() {
      return !this.lead_acquired
    },
    chartData() {
      const chartHelper = new ChartHelper()
      chartHelper.setPalette(Palette.fuchsia)
      if (this.label_format)
        chartHelper.setLabelFormat(this.label_format)
      chartHelper.addStats(this.lead_acquired, 'Lead Acquire')

      const chartOptions = new ChartOptions()
      chartOptions.isStacked()


      return {
        labels: chartHelper.getXLabels(),
        datasets: chartHelper.getDatasets(),
        options: chartOptions.getOptions()
      }
    }
  }
}
</script>
