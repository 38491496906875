<template>
  <div class="kpi" :class="['kpi--' + palette.name]">
    <ViewLoadingMessage extra-class="mt-5" :loading="loading" :size="`sm`"/>
    <WidgetKpi :heading="$gettext('Avg photos per user')"
                            :heading-icon="`cipPicture`"
                            :palette="palette"
                            :heading-value="photographicProductionSummary.avg_photos_per_participant|number"
                            v-if="!loading">
      <CListGroup flush>
        <CListGroupItem
            class="flex-column align-items-start px-0 pt-1 pb-1 border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate>Total uploaded photos</span> <span
              class="font-weight-bold">{{
              photographicProductionSummary.total_count|number
            }}</span>
          </div>
        </CListGroupItem>
        <CListGroupItem
            class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex align-items-start">
              <span v-translate>Total delivered photos</span>
              <PTooltip :content="tooltipContent.deliveredPhotos" placement="left" :color="tooltipColor"/>
            </div>
            <span class="font-weight-bold">{{
                photographicProductionSummary.total_delivered_count|number
              }}</span>
          </div>
        </CListGroupItem>
      </CListGroup>
    </WidgetKpi>
  </div>
</template>

<script>
import PTooltip from "@/domain/core/components/PTooltip.vue";
import WidgetKpi from "@/views_pica/stats/components/WidgetKpi.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import Palette from "@/domain/core/utils/chart/Palette";
import {tooltipColor, tooltipContent} from "@/domain/eventMetric/tooltipContent";
import {mapGetters} from "vuex";

export default {
  name: "PhotographicProductionKpi",
  components: {ViewLoadingMessage, WidgetKpi, PTooltip},

  computed: {
    ...mapGetters('stats', ['photographicProductionSummary']),
    Palette() {
      return Palette
    },
    tooltipColor() {
      return tooltipColor
    },
    tooltipContent() {
      return tooltipContent
    },
     loading() {
      return Object.keys(this.photographicProductionSummary).length === 0 || this.photographicProductionSummary === null
    },
    palette() {
      return {
        name: 'orange',
        color: Palette.orange[0]
      }
    },
  },
}
</script>


<style scoped>

</style>