<template>
  <CCard :class="['stat-widget-card', {'stat-widget-card--clean': !asCard}]" :style="`color:${color}`">
    <CCardHeader :class="cardHeaderClass">
      <div class="d-flex">
        <h5 class="m-0">{{ heading }}</h5>
        <PTooltip v-if="tooltip" :content="tooltip" :color="tooltipColor" :placement="tooltipPlacement" />
      </div>
    </CCardHeader>
    <CCardBody :class="cardBodyClass">
      <slot></slot>
    </CCardBody>
  </CCard>
</template>

<script>
import {tooltipColor} from "@/domain/eventMetric/tooltipContent";
import PTooltip from "@/domain/core/components/PTooltip.vue";

export default {
  name: "WidgetCard",
  components: {PTooltip},
  props: {
    asCard: {
      type: Boolean,
      required: false,
      default: true
    },
    color: {
      type: String,
      required: false,
    },
    heading: {
      type: String,
      required: false
    },
    headingIcon: {
      type: String,
      required: false
    },
    headingValue: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    cardBodyClass: {
      type: Array,
      required: false
    },
    cardHeaderClass: {
      type: Array,
      required: false,
      default: () => ['border-bottom-0']
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top'
    }
  },
  computed: {
    tooltipColor() {
      return tooltipColor
    }
  }
}
</script>

<style scoped>

</style>