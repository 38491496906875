export const chartOptionsDoughnut = {
    chartOptionsDoughnut: {
        plugins: {
            tooltip: {
                usePointStyle: true,
                boxPadding: 5,
                boxHeight: 12,
                boxWidth: 12,
                backgroundColor: '#dcdbdb',
                titleColor: '#100F23',
                titleFont: {
                    size: 14,
                    family: 'Inter, sans-serif'
                },
                bodyColor: '#100F23',
                bodyFont: {
                    size: 14,
                    family: 'Inter, sans-serif'
                },


            },
            emptyDoughnut: {
                color: 'rgba(115,114,114,0.5)',
                width: 2,
                radiusDecrease: 20
            },
            title: {
                display: false,
            },
            legend: {
                display: false,
            }
        },
        //responsive: true,
        //maintainAspectRatio: false,
    }
}
