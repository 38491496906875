import moment from "moment";

export default class ChartHelper {
    stats = {}
    palette = []
    types = []
    labels = null
    exclude_empty = false
    sort_by_number = false

    setPalette(palette) {
        this.palette = palette
        this.labelFormat = null
    }

    excludeEmpty(value = true) {
        this.exclude_empty = value
    }

    sortByNumber(value = true) {
        this.sort_by_number = value
    }

    setLabelFormat(format) {
        this.labelFormat = format
    }

    addStats(rows, type, stack = null) {
        if (this.exclude_empty && Object.keys(rows).length === 0)
            return

        this.stats[type] = {'rows': rows}
        if (stack) this.stats[type]['stack'] = stack
        this._addRowsToLabel(Object.keys(rows).sort())

        this.types.push(type)
    }

    _addRowsToLabel(labels) {
        if (!this.labels) this.labels = []
        for (let label of labels) {
            if (!this.labels.includes(label)) this.labels.push(label)
        }
    }


    getXLabels() {
        const labels = this.labels.sort()
        if (!this.labelFormat)
            return labels
        return labels.map(label => moment(label).format(this.labelFormat))
    }

    getDatasets() {
        let types = this.types
        if (this.sort_by_number) {
            for (let type of types)
                this.stats[type]['total'] = this._getTotal(this.stats[type]['rows'])
            types = types.sort((a, b) => this.stats[b]['total'] - this.stats[a]['total'])
        }

        let datasets = []
        for (let i = 0; i < types.length; i++)
            datasets.push(this._getDataset(types[i], i))

        return datasets
    }

    getNumDatasets() {
        return this.types.length
    }

    _getDataset(type, index) {
        const dataSet = {
            data: this.labels.map(label => this.stats[type]['rows'][label]),
            backgroundColor: this._getColor(index),
            label: type,
        }
        if (this.stats[type]['stack']) dataSet['stack'] = this.stats[type]['stack']
        return dataSet
    }

    _getColor(i) {
        return this.palette[i % this.palette.length]
    }

    _getTotal(rows) {
        return Object.values(rows).reduce((a, b) => a + b, 0)
    }
}