<template>
  <div>
    <Bar :chart-data="chartData" :chart-options="chartOptions"/>
  </div>
</template>

<script>
import ChartOptions from "@/domain/core/utils/chart/ChartOptions";
import {Bar} from "vue-chartjs/legacy";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import ChartDataHelper from "@/views_pica/stats/ChartDataHelper";
import Palette from "@/domain/core/utils/chart/Palette";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "CheckinsChart",
  components: {Bar},
  props: {
    chartDataRaw: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      required: false,
    },
    label_format: {
      type: String,
      required: true
    }
  },
  computed: {
    chartData() {
      const chartDataHelper = new ChartDataHelper()
      chartDataHelper.setLabelFormat(this.label_format)
      chartDataHelper.excludeEmpty(true)
      chartDataHelper.setPalette(Palette.purple)

      return  chartDataHelper.addStats(this.chartDataRaw)
    },
    chartOptions() {
      const chartOptions = new ChartOptions()
      chartOptions.isStacked()
      chartOptions.isInteractive(true)
      chartOptions.setTooltipCallbacks({
        label: this.getTooltipLabels,
        footer: this.getTooltipFooter
      })

      chartOptions.enableLegend(this.chartData.datasets.length > 1)

      return chartOptions.getOptions()
    },
  },
  methods: {
    getTooltipLabels(context) {
      let label = context.dataset.label || '';
      let sum = context.dataset.data[context.dataIndex].sum
      if (context.parsed.y !== null && !isNaN(sum)) {
        label += `: ${context.parsed.y} (${this.$options.filters.currency(sum, this.currency)})`;
      } else {
        label += `: ${context.parsed.y}`;
      }
      return label;
    },
    getTooltipFooter(context) {
      let sum = 0;
      let showTotal = false
      let totalLabel = this.$gettext('Total')
      let isAmount = false
      context.forEach(function (tooltipItem) {
        if (!isNaN(tooltipItem.dataset.data[tooltipItem.dataIndex].sum)) {
          sum += tooltipItem.dataset.data[tooltipItem.dataIndex].sum

          isAmount = true
          showTotal = true
        } else {
          sum += tooltipItem.parsed.y
          showTotal = true
        }
      });
      isAmount ? sum = this.$options.filters.currency(sum, this.currency) : sum
      isAmount ? totalLabel = this.$gettext('Total amount') : totalLabel
      return showTotal ? `${totalLabel}: ` + sum : null
    },
  }
}
</script>


<style scoped>

</style>