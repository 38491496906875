<template>
  <div>
    <div class="kpi__head">
      <div class="d-flex">
        <div>
          <CIcon :name="headingIcon" width="40" size="custom-size" class="mr-2 icon-circle" :style="textColor"/>
        </div>
        <div>
          <div class="d-flex">
            <h6 class="m-0">{{ heading }}</h6>
            <PTooltip class="ml-1" v-if="tooltip" :content="tooltip" :color="tooltipColor"
                      :placement="tooltipPlacement"/>
          </div>
          <div class="value" :style="textColor">{{ headingValue }}</div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipColor} from "@/domain/eventMetric/tooltipContent";

export default {
  name: "WidgetKpi",
  components: {PTooltip},

  props: {
    palette: {
      type: Object,
      required: false,
    },
    heading: {
      type: String,
      required: false
    },
    headingIcon: {
      type: String,
      required: false
    },
    headingValue: {
      type: [String, Number],
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top'
    }
  },
  computed: {
    tooltipColor() {
      return tooltipColor
    },
    textColor() {
      return {
        color: this.palette.color
      }
    },
  },
}
</script>

<style scoped>

</style>