<template>
  <div>
    <ViewLoadingMessage :loading="loading" :extra-class="'py-5'" :size="`sm`"/>
    <div class="table-responsive-md" v-if="!loading">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>#</th>
          <th v-for="type in shareTypes" :key="type.label">{{ type.label }}</th>
          <th v-translate translate-context="stats.shareTypeEffectiveness">Total</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" v-translate translate-context="stats.shareTypeEffectiveness">Shared media</th>
          <td v-for="type in shareTypes" :key="type.label">{{ type.share }}
            ({{ getPercentage(totalShare, type.share)|number }}%)
          </td>
          <td>{{ totalShare|number }}</td>
        </tr>
        <tr>
          <th scope="row" v-translate translate-context="stats.shareTypeEffectiveness">Content promoters</th>
          <td v-for="type in shareTypes" :key="type.label">{{ type.unique }}</td>
          <td>{{ totalUnique|number }}</td>
        </tr>
        <tr>
          <th scope="row">
            <div class="d-flex">
              <span v-translate translate-context="stats.shareTypeEffectiveness">Social views</span>
              <PTooltip :content="tooltipContent.socialViews" :color="tooltipColor" placement="right"/>
            </div>
          </th>
          <td v-for="type in shareTypes" :key="type.label">
            <span v-if="type.views <= 0">N/A</span>
            <span v-else>{{ type.views|number }} ({{ getPercentage(totalViews, type.views)|number }}%)</span>
          </td>
          <td>
            {{ totalViews|number }}
          </td>
        </tr>
        <tr>
          <th scope="row" class="font-weight-bold">
            <div class="d-flex">
              <span v-translate translate-context="stats.shareTypeEffectiveness">Share multiplier</span>
              <PTooltip :content="tooltipContent.shareMultiplier" :color="tooltipColor" placement="right"/>
            </div>
          </th>
          <td class="font-weight-bold" v-for="type in shareTypes" :key="type.label">
            <span v-if="type.share <= 0 || type.views <= 0">N/A</span>
            <span v-else>{{ (type.views / type.share)|number }}x</span>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import {getPercentage} from "@/utils/functions";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipContent, tooltipColor} from "@/domain/eventMetric/tooltipContent";

export default {
  name: 'ShareTypeEffectiveness',
  components: {PTooltip, ViewLoadingMessage},
  methods: {getPercentage},
  props: {
    facebook_share: {
      type: Object,
      required: false
    },
    facebook_share_unique: {
      type: Number,
      required: false
    },
    facebook_views: {
      type: Object,
      required: false
    },
    email_share: {
      type: Object,
      required: false
    },
    email_share_unique: {
      type: Number,
      required: false
    },
    email_views: {
      type: Object,
      required: false
    },
    whatsapp_share: {
      type: Object,
      required: false
    },
    whatsapp_share_unique: {
      type: Number,
      required: false
    },
    whatsapp_views: {
      type: Object,
      required: false
    },
    twitter_share: {
      type: Object,
      required: false
    },
    twitter_share_unique: {
      type: Number,
      required: false
    },
    twitter_views: {
      type: Object,
      required: false
    },
    telegram_share: {
      type: Object,
      required: false
    },
    telegram_share_unique: {
      type: Number,
      required: false
    },
    telegram_views: {
      type: Object,
      required: false
    },
    linkedin_share: {
      type: Object,
      required: false
    },
    linkedin_share_unique: {
      type: Number,
      required: false
    },
    linkedin_views: {
      type: Object,
      required: false
    }
  },
  computed: {
    tooltipContent() {
      return tooltipContent
    },
    tooltipColor() {
      return tooltipColor
    },
    loading() {
      return !this.facebook_share
          || !this.facebook_views
          || !this.email_share
          || !this.email_views
          || !this.whatsapp_share
          || !this.whatsapp_views
          || !this.twitter_share
          || !this.twitter_views
          || !this.telegram_share
          || !this.telegram_views
          || !this.linkedin_share
          || !this.linkedin_views
          || isNaN(this.facebook_share_unique)
          || isNaN(this.email_share_unique)
          || isNaN(this.whatsapp_share_unique)
          || isNaN(this.twitter_share_unique)
          || isNaN(this.telegram_share_unique)
          || isNaN(this.linkedin_share_unique)

    },
    shareTypes() {
      let types = [
        {
          label: 'Facebook',
          share: this.totals.facebook_share,
          unique: this.facebook_share_unique,
          views: this.totals.facebook_views,
        },
        {
          label: 'WhatsApp',
          share: this.totals.whatsapp_share,
          unique: this.whatsapp_share_unique,
          views: this.totals.whatsapp_views,
        },
        {
          label: 'X',
          share: this.totals.twitter_share,
          unique: this.twitter_share_unique,
          views: this.totals.twitter_views,
        },
        {
          label: 'Telegram',
          share: this.totals.telegram_share,
          unique: this.telegram_share_unique,
          views: this.totals.telegram_views,
        },
        {
          label: 'LinkedIn',
          share: this.totals.linkedin_share,
          unique: this.linkedin_share_unique,
          views: this.totals.linkedin_views,
        },
        {
          label: 'Email',
          share: this.totals.email_share,
          unique: this.email_share_unique,
          views: this.totals.email_views,
        }
      ]

      return types.sort((a, b) => a.label.localeCompare(b.label))
    },
    totals() {
      const totalHelper = new TotalHelper()
      return {
        'facebook_share': totalHelper.getTotal(this.facebook_share),
        'facebook_views': totalHelper.getTotal(this.facebook_views),
        'email_share': totalHelper.getTotal(this.email_share),
        'email_views': totalHelper.getTotal(this.email_views),
        'whatsapp_share': totalHelper.getTotal(this.whatsapp_share),
        'whatsapp_views': totalHelper.getTotal(this.whatsapp_views),
        'twitter_share': totalHelper.getTotal(this.twitter_share),
        'twitter_views': totalHelper.getTotal(this.twitter_views),
        'telegram_share': totalHelper.getTotal(this.telegram_share),
        'telegram_views': totalHelper.getTotal(this.telegram_views),
        'linkedin_share': totalHelper.getTotal(this.linkedin_share),
        'linkedin_views': totalHelper.getTotal(this.linkedin_views)
      }
    }, totalShare() {
      return this.totals.facebook_share +
          this.totals.email_share +
          this.totals.whatsapp_share +
          this.totals.twitter_share +
          this.totals.telegram_share +
          this.totals.linkedin_share
    }, totalViews() {
      return this.totals.facebook_views +
          this.totals.email_views +
          this.totals.whatsapp_views +
          this.totals.twitter_views +
          this.totals.telegram_views +
          this.totals.linkedin_views
    }, totalUnique() {
      return this.facebook_share_unique +
          this.email_share_unique +
          this.whatsapp_share_unique +
          this.twitter_share_unique +
          this.telegram_share_unique +
          this.linkedin_share_unique
    }

  }
}
</script>
