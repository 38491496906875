var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ViewLoadingMessage',{staticClass:"pb-5",attrs:{"loading":_vm.loading}}),(!_vm.loading)?[_c('CDataTable',{staticClass:"custom-table custom-table--clean custom-table--border-top mb-4",attrs:{"striped":true,"items":_vm.releasesItems,"fields":_vm.releasesDataFields},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h6',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("No data")])])]},proxy:true},{key:"release_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.race_number_from)+" - "+_vm._s(item.race_number_to)+" ")])])]}},{key:"price_list_name",fn:function(ref){
var item = ref.item;
return [_c('td',[(!item.price_list_name)?_c('CBadge',{staticClass:" badge-outline",attrs:{"color":"danger"}},[_c('translate',[_vm._v("NOT SET")])],1):(item.price_list_name === 'free')?_c('CBadge',{staticClass:" badge-outline",attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm._f("upper")(item.price_list_name))+" ")]):_c('CBadge',{staticClass:"badge-outline",attrs:{"color":"success"}},[_vm._v(_vm._s(_vm._f("upper")(item.price_list_name)))])],1)]}},{key:"checkin_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Recognized participants")]),_vm._v(" "),_c('span',{staticClass:"font-weight-semi-bold"},[_vm._v(_vm._s(_vm._f("number")(item.recognized_participants)))])]),_c('div',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Check-ins")]),_vm._v(" "),_c('span',{staticClass:"font-weight-semi-bold"},[_vm._v(_vm._s(_vm._f("number")(item.release_checkin_quantity)))])])])]}},{key:"recognized_participants",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.getPercentage(item.recognized_participants,item.release_checkin_quantity)))+"% ")])]}},{key:"order_conversion_rate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("number")((item.order_conversion_rate * 100)))+"% ")])]}},{key:"order_count",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("number")(item.order_count))+" ")])]}},{key:"order_amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"font-weight-semi-bold"},[_vm._v(_vm._s(_vm._f("currency")(item.order_amount,_vm.currency)))])])]}}],null,false,2686763322)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }