<template>
  <div class="kpi" :class="['kpi--' + palette.name]">
    <ViewLoadingMessage extra-class="mt-5" :loading="loading" :size="`sm`"/>
    <WidgetKpi :heading="$gettext('Amount')"
                            :heading-icon="`cipCreditCard02`"
                            :palette="palette"
                            :heading-value="ordersSummary.total_amount|currency(eventCurrency)"
                            v-if="!loading">
      <CListGroup flush>
        <template v-if="isResort">
          <CListGroupItem v-for="(item, key) in ordersSummary.by_stay"
                          class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0"
                          :key="key">
            <div class="d-flex w-100 justify-content-between">
              <span>{{ item.label }} ({{ item.orders_count|number }})</span>
              <span class="font-weight-bold">{{
                  item.amount|currency(eventCurrency)
                }}</span>
            </div>
          </CListGroupItem>
        </template>
        <template v-else>
          <CListGroupItem class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
            <div class="d-flex w-100 justify-content-between">
              <span v-translate>Total orders</span>
              <span class="font-weight-bold">{{ ordersSummary.total_orders|number }}</span>
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="ordersSummary && !isMultiEvents"
                          class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
            <div class="d-flex w-100 justify-content-between">
              <span>{{ isActivation ? $gettext('CR/Activations') : $gettext('CR/Check-ins') }}</span>
              <span class="font-weight-bold">{{
                  checkinsConversionRate|number
                }}%</span>
            </div>
          </CListGroupItem>
        </template>
      </CListGroup>
    </WidgetKpi>
  </div>
</template>


<script>
import WidgetKpi from "@/views_pica/stats/components/WidgetKpi.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import Palette from "@/domain/core/utils/chart/Palette";
import {mapGetters} from "vuex";

export default {
  name: "OrdersKpi",
  components: {ViewLoadingMessage, WidgetKpi},
  data() {
    return {};
  },
  props: {
    isResort: {
      type: Boolean,
      default: false,
    },
    isMultiEvents: {
      type: Boolean,
      default: false,
    },
    isActivation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('stats', [
      'eventCurrency',
      'ordersSummary',
      'checkinsConversionRate']),
    Palette() {
      return Palette
    },
    loading() {
      return Object.keys(this.ordersSummary).length === 0 || this.ordersSummary === null
    },
    palette() {
      return {
        name: 'blue',
        color: Palette.blue[0]
      }
    }
  }
}
</script>


<style scoped>

</style>