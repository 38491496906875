<template>
  <ul class="nav nav-pills chart-pills"
      :class="{'hide-tooltip': !active}">
    <li class="nav-item">
      <a class="nav-link" href="#" v-translate
         :class="{'active': active && countBy===period.DAY, 'disabled': !active}"
         @click.prevent="active ? onClick(period.DAY): null">Day</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" v-translate
         :class="{'active': active && countBy===period.MONTH, 'disabled': !active}"
         @click.prevent="active ? onClick(period.MONTH): null">Month</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" v-translate
         :class="{'active': active && countBy===period.YEAR, 'disabled': !active}"
         @click.prevent="active ? onClick(period.YEAR): null">Year</a>
    </li>
  </ul>
</template>

<script>
import {period} from "@/domain/eventMetric/consts";
export default {
  name: "CountBy",
  emits: ['change'],
  props: {
    countBy: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    period() {
      return period
    },
  },
  methods: {
    onClick(countBy) {
      return this.$emit('change', countBy)
    }
  }
}
</script>


<style scoped>

</style>