<template>
  <CRow>
    <CCol sm="6">
      <ul class="list-group list-group-flush legend">
        <li class="list-group-item px-0 border-bottom-0"
            v-for="(item, index) in legendItems" :key="index">
          <div class="d-flex flex-row align-items-start">
            <div>
              <CIcon name="cipDot" width="12" class="mr-2" :style="`color:${item.color}`"></CIcon>
            </div>
            <div class="d-flex flex-column">
              <div>
                  <span class="legend__label font-weight-bold">{{ item.label }}</span><br>
                  <div>{{item.count_label}}: {{ item.count|number }} ({{ item.percentage|number }}%)</div>
                  <div v-if="!isNaN(item.count_2)">{{item.count_label_2}}: {{ item.count_2|number }} <span v-if="item.percentage_2" style="display: none">({{ item.percentage_2|number }}%)</span></div>
              </div>
              <div class="legend__amount" v-if="item.amount">{{ item.amount | currency(currency) }}</div>
            </div>
          </div>
        </li>
      </ul>
    </CCol>
    <CCol sm="6">
      <Doughnut :chart-data="chartData" :chart-options="chartOptionsDoughnut"
                :class="{'doughnut-empty': chartData.isEmpty}"/>
    </CCol>
  </CRow>
</template>

<script>
import {Doughnut} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement} from 'chart.js'
import {chartOptionsDoughnut} from "@/views_pica/stats/chartOptions";


ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement)
export default {
  name: "WidgetDoughnut",
  components: {Doughnut},
  data() {
    return {
      ...chartOptionsDoughnut
    }
  },
  props: {
    legendItems: {
      type: Array,
      required: true,
    },
    chartData: {
      type: Object,
      required: true
    },
     currency: {
      type: String,
      required: false
    }
  },
}
</script>

<style scoped>

</style>