<template>
  <div>
    <CRow>
      <CCol xl="8" class="mb-5">
        <div class="d-flex align-items-center justify-content-lg-center mb-4">
          <h5 class="font-weight-semi-bold mr-3 mb-0 chart-pills__label" v-translate>
            Sharing channels</h5>
          <CountBy :active="periodEnabled" :count-by="countBy" @change="setCountBy"/>
        </div>
        <div>
          <ShareByPeriod
              v-if="configuration.share_by_period"
              :instagram="shareLinkByShareType[SHARE_TYPES.INSTAGRAM]"
              :facebook="shareLinkByShareType[SHARE_TYPES.FACEBOOK]"
              :whatsapp="shareLinkByShareType[SHARE_TYPES.WHATSAPP]"
              :twitter="shareLinkByShareType[SHARE_TYPES.TWITTER]"
              :telegram="shareLinkByShareType[SHARE_TYPES.TELEGRAM]"
              :linkedin="shareLinkByShareType[SHARE_TYPES.LINKEDIN]"
              :email="shareLinkByShareType[SHARE_TYPES.EMAIL]"
              :download="groupByType[METRIC_TYPES.MEDIA_DOWNLOADED]"
              :label_format="countBy.format"
          />
        </div>
      </CCol>

      <CCol xl="4" class="mb-5">
        <ActiveUsers
            :active_users="activeUsers"
            :shared_link_created="groupByType[METRIC_TYPES.SHARED_LINK_CREATED]"
            :shared_link_opened="groupByType[METRIC_TYPES.SHARED_LINK_OPENED]"
            :media_downloaded="groupByType[METRIC_TYPES.MEDIA_DOWNLOADED]"
            :photo_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_PHOTO]"
            :video_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_VIDEO]"
            :unique_media_downloaded="groupByType[METRIC_TYPES.UNIQUE_MEDIA_DOWNLOADED]"
            :can_download="hasPerm('dashboard.view_feature_beta')"
            @download="downloadSharedMedia"
        />
      </CCol>


      <CCol xl="12" class="mb-2" v-if="configuration.share_type_effectiveness">
        <CCard class="chart-card chart-card&#45;&#45;full-h">
          <CCardHeader>
            <h5 v-translate>Breakdown of views by sharing channel</h5>
          </CCardHeader>
          <CCardBody>
            <ShareTypeEffectiveness
                :facebook_views="groupByShareType[SHARE_TYPES.FACEBOOK]"
                :facebook_share="shareLinkByShareType[SHARE_TYPES.FACEBOOK]"
                :facebook_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_FACEBOOK]"
                :whatsapp_views="groupByShareType[SHARE_TYPES.WHATSAPP]"
                :whatsapp_share="shareLinkByShareType[SHARE_TYPES.WHATSAPP]"
                :whatsapp_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_WHATSAPP]"
                :twitter_views="groupByShareType[SHARE_TYPES.TWITTER]"
                :twitter_share="shareLinkByShareType[SHARE_TYPES.TWITTER]"
                :twitter_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_TWITTER]"
                :telegram_views="groupByShareType[SHARE_TYPES.TELEGRAM]"
                :telegram_share="shareLinkByShareType[SHARE_TYPES.TELEGRAM]"
                :telegram_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_TELEGRAM]"
                :linkedin_views="groupByShareType[SHARE_TYPES.LINKEDIN]"
                :linkedin_share="shareLinkByShareType[SHARE_TYPES.LINKEDIN]"
                :linkedin_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_LINKEDIN]"
                :email_views="groupByShareType[SHARE_TYPES.EMAIL]"
                :email_share="shareLinkByShareType[SHARE_TYPES.EMAIL]"
                :email_share_unique="shareLinkByShareType[METRIC_TYPES.UNIQUE_SHARE_EMAIL]"
            />
          </CCardBody>
        </CCard>
      </CCol>

      <CCol xl="12" class="mb-2" v-if="configuration.media_view_by_period">
        <CCard>
          <CCardHeader>
            <h5 v-translate>Media Interactions</h5>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xl="8">
                <MediaViewByPeriod
                    :photo_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_PHOTO]"
                    :video_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_VIDEO]"
                    :photo_opened="groupByType[METRIC_TYPES.MEDIA_OPENED_PHOTO]"
                    :video_opened="groupByType[METRIC_TYPES.MEDIA_OPENED_VIDEO]"
                    :label_format="countBy.format"
                />
              </CCol>
              <CCol xl="4" class="mb-2" v-if="configuration.media_view_by_period">
                <TotalMediaInteractions
                    :photo_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_PHOTO]"
                    :video_viewed="groupByType[METRIC_TYPES.MEDIA_VIEWED_VIDEO]"
                    :photo_opened="groupByType[METRIC_TYPES.MEDIA_OPENED_PHOTO]"
                    :video_opened="groupByType[METRIC_TYPES.MEDIA_OPENED_VIDEO]"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>


      <CCol xl="12" class="mb-2" v-if="configuration.banner_viewed_by_period">
        <CCard>
          <CCardHeader>
            <h5 v-translate>Banners Interactions</h5>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xl="8">
                <BannerViewedByPeriod
                    :banner_viewed="groupByType[METRIC_TYPES.BANNER_VIEWED]"
                    :banner_clicked="groupByType[METRIC_TYPES.BANNER_CLICKED]"
                    :label_format="countBy.format"
                />
              </CCol>
              <CCol xl="4" class="mb-2" v-if="configuration.banner_viewed_by_period">
                <TotalBannerInteractions
                    :banner_viewed="groupByType[METRIC_TYPES.BANNER_VIEWED]"
                    :banner_clicked="groupByType[METRIC_TYPES.BANNER_CLICKED]"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>


      <CCol xl="6" class="mb-2" v-if="configuration.lead_acquired_by_period">
        <CCard>
          <CCardHeader>
            <h5 v-translate>Leads aquired by period</h5>
          </CCardHeader>
          <CCardBody>
            <LeadAcquiredByPeriod
                :lead_acquired="groupByType[METRIC_TYPES.LEAD_ACQUIRED]"
                :label_format="countBy.format"
            />
          </CCardBody>
        </CCard>
      </CCol>

      <CCol xl="6" class="mb-2" v-if="configuration.lead_acquired_by_period">
        <CCard class="chart-card chart-card&#45;&#45;full-h">
          <CCardHeader>
            <h5 v-translate>Total leads acquired</h5>
          </CCardHeader>
          <CCardBody>
            <TotalLeadAcquired :lead_acquired="groupByType[METRIC_TYPES.LEAD_ACQUIRED]"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <!--      <CRow v-if="configuration.influencer_most_viewed && hasPerms(['dashboard.view_feature_beta'])">
            <CCol class="mb-2">
              <CCard>
                <CCardHeader>
                  <h5 v-translate>Influencers with most views</h5>
                </CCardHeader>
                <CCardBody>
                  <InfluencerMostViewed :data="influencerMostViewed"/>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>

          <CRow v-if="configuration.influencer_most_viewed && hasPerms(['dashboard.view_feature_beta'])">
            <CCol class="mb-2">
              <CCard>
                <CCardHeader>
                  <h5 v-translate>Most shared media</h5>
                </CCardHeader>
                <CCardBody>
                  <MostSharedMedia :data="mostSharedMedia"/>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>-->

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {period, METRIC_TYPES, SHARE_TYPES} from "@/domain/eventMetric/consts";
import ShareByPeriod from "@/domain/eventMetric/graph/ShareByPeriod.vue";
import MostSharedMedia from "@/domain/eventMetric/graph/MostSharedMedia.vue";
import MediaViewByPeriod from "@/domain/eventMetric/graph/MediaViewByPeriod.vue";
import BannerViewedByPeriod from "@/domain/eventMetric/graph/BannerViewedByPeriod.vue";
import LeadAcquiredByPeriod from "@/domain/eventMetric/graph/LeadAcquiredByPeriod.vue";
import TotalLeadAcquired from "@/domain/eventMetric/graph/TotalLeadAcquired.vue";
import InfluencerMostViewed from "@/domain/eventMetric/graph/InfluencerMostViewed.vue";
import ShareTypeEffectiveness from "@/domain/eventMetric/graph/ShareTypeEffectiveness.vue";
import CountBy from "@/domain/eventMetric/components/CountBy.vue";
import ActiveUsers from "@/domain/eventMetric/graph/ActiveUsers.vue";
import TotalMediaInteractions from "@/domain/eventMetric/graph/TotalMediaInteractions.vue";
import TotalBannerInteractions from "@/domain/eventMetric/graph/TotalBannerInteractions.vue";

export default {
  name: 'Metrics',
  components: {
    TotalBannerInteractions,
    TotalMediaInteractions,
    CountBy,
    ShareTypeEffectiveness,
    TotalLeadAcquired,
    LeadAcquiredByPeriod,
    BannerViewedByPeriod,
    MediaViewByPeriod,
    ActiveUsers,
    ShareByPeriod
  },
  props: {
    date_from: {
      type: [Date, null],
      required: true
    },
    date_to: {
      type: [Date, null],
      required: true
    },
    need_date: {
      type: Boolean,
      required: true
    },
    time_zone: {
      type: String,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      countBy: period.DAY,
      typeGroups: [
        [
          METRIC_TYPES.MEDIA_VIEWED,
          METRIC_TYPES.MEDIA_VIEWED_VIDEO, // derived metric
          METRIC_TYPES.MEDIA_VIEWED_PHOTO, // derived metric
        ],
        [
          METRIC_TYPES.MEDIA_OPENED,
          METRIC_TYPES.MEDIA_OPENED_VIDEO, // derived metric
          METRIC_TYPES.MEDIA_OPENED_PHOTO, // derived metric
        ],
        [
          METRIC_TYPES.MEDIA_DOWNLOADED,
          METRIC_TYPES.MEDIA_DOWNLOADED_VIDEO, // derived metric
          METRIC_TYPES.MEDIA_DOWNLOADED_PHOTO, // derived metric
          METRIC_TYPES.UNIQUE_MEDIA_DOWNLOADED, // derived metric
        ],
        [
          METRIC_TYPES.BANNER_CLICKED,
          METRIC_TYPES.BANNER_VIEWED,
        ],
        [
          METRIC_TYPES.SHARED_LINK_OPENED,
          METRIC_TYPES.SHARED_LINK_CREATED,
        ],
        [
          METRIC_TYPES.ALBUM_SESSION_NUMBER,
          METRIC_TYPES.TIME_IN_ALBUM, // derived metric
          METRIC_TYPES.LEAD_ACQUIRED,
        ]
      ],
      numMostSharedMedia: 10,
      numInfluencerMostViewed: 10,

      //TODO: da Rivedere
      periodEnabled: true, // serve a disabilitare count_by

    }
  },
  watch: {
    date_from: function () {
      this.getStats()
    },
    date_to: function () {
      this.getStats()
    }
  },
  async mounted() {
    await this.getStats()
  },
  computed: {
    SHARE_TYPES() {
      return SHARE_TYPES
    },
    METRIC_TYPES() {
      return METRIC_TYPES
    },
    period() {
      return period
    },
    ...mapGetters('eventMetric', [
      'groupByType',
      'mostSharedMedia',
      'influencerMostViewed',
      'groupByShareType',
      'shareLinkByShareType',
      'activeUsers',
    ])
  },
  methods: {
    ...mapActions('eventMetric', [
      'fetchGropByType',
      'fetchMostSharedMedia',
      'fetchInfluencerMostViewed',
      'fetchGroupByShareType',
      'fetchShareLinkByShareType',
      'fetchActiveUsers',
      'setDates',
      'setTimezone',
      'resetData',
      'downloadSharedMedia'
    ]),
    setCountBy(countBy) {
      this.countBy = countBy
      this.getStats()
    },
    async getStats() {
      if (!this.configuration) return

      this.resetData()

      if (this.need_date)
        this.setDates({from: moment(this.date_from), to: moment(this.date_to)})
      else
        this.setDates({from: moment('2000-01-01'), to: moment('2030-01-01')})
      this.setTimezone(this.time_zone)

      this.resetData()

      let promises = []
      if (this.configuration.share_by_period ||
          this.configuration.share_type_effectiveness ||
          this.configuration.media_view_by_period ||
          this.configuration.banner_viewed_by_period ||
          this.configuration.lead_acquired_by_period ||
          this.configuration.share_pie) {
        for (let i = 0; i < this.typeGroups.length; i++) {
          promises.push(this.fetchGropByType({
            countBy: this.countBy.suffix,
            types: this.typeGroups[i]
          }))
        }
      }


      if (this.configuration.share_type_effectiveness
          || this.configuration.link_view_by_period
      ) {
        promises.push(this.fetchGroupByShareType({
          countBy: this.countBy.suffix
        }))
      }

      if (this.configuration.share_type_effectiveness) {
        promises.push(this.fetchShareLinkByShareType({
          countBy: this.countBy.suffix
        }))
      }

      // if (this.configuration.most_shared_media) {
      //   promises.push(this.fetchMostSharedMedia({
      //     num: this.numMostSharedMedia
      //   }))
      // }

      // if (this.configuration.influencer_most_viewed) {
      //   promises.push(this.fetchInfluencerMostViewed({
      //     num: this.numInfluencerMostViewed
      //   }))
      // }

      promises.push(this.fetchActiveUsers())


      await Promise.all(promises)
    }
  }
}
</script>
