export default class ChartOptions {
    _interactive = true
    _stacked = true
    _legend = true
    _tooltipCallbacks = {}

    isInteractive(value = true) {
        this._interactive = value
    }

    isStacked(value = true) {
        this._stacked = value
    }

    enableLegend(value = true) {
        this._legend = value
    }

    setTooltipCallbacks(value = {}) {
        this._tooltipCallbacks = value
    }

    getOptions() {
        return {
            responsive: true,
            maintainAspectRatio: false,
            ...this._getInteraction(),
            ...this._getStacked(),
            plugins: {
                title: {
                    display: false,
                },
                ...this._getTooltip(),
                ...this._getLegend(),
            },
        }
    }

    _getInteraction() {
        if (!this._interactive) return {}
        return {
            interaction: {
                intersect: false,
                mode: 'index',
            }
        }
    }

    _getStacked() {
        if (!this._stacked) return {}
        return {
            scales: {
                x: {stacked: true},
                y: {stacked: true}
            }
        }
    }

    _getTooltip() {
        return {
            tooltip: {
                padding: 12,
                usePointStyle: true,
                boxPadding: 5,
                boxHeight: 12,
                boxWidth: 12,
                backgroundColor: '#dcdbdb',
                titleMarginBottom: 10,
                titleColor: '#100F23',
                titleFont: {
                    size: 13,
                    family: 'Inter, sans-serif'
                },
                bodySpacing: 8,
                bodyColor: '#100F23',
                bodyFont: {
                    size: 13,
                    family: 'Inter, sans-serif'
                },
                footerColor: '#100F23',
                footerMarginTop: 8,
                footerFont: {
                    size: 13,
                    weight: 500,
                    family: 'Inter, sans-serif'
                },
                callbacks: {
                    ...this._getTooltipCallbacks()
                }


            }
        }
    }

    _getLegend() {
        if (!this._legend) return {legend: {display: false}}
        return {
            legend: {
                display: true,
                labels: {
                    boxHeight: 12,
                    boxWidth: 12,
                    useBorderRadius: true,
                    borderRadius: 6
                }
            }
        }
    }

    _getTooltipCallbacks() {
        return this._tooltipCallbacks
    }

}