<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-0'" :loading="loading" :size="`sm`"/>
    <CListGroup flush class="list-striped list-striped--even" v-if="data">
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h6 class="m-0" v-translate>Amount</h6>
          <CBadge color="success" class="font-weight-bold" shape="pill"
                  style="font-size: 15px">
            {{ data.total_amount|currency(currency) }}</CBadge>
        </div>
      </CListGroupItem>

      <CListGroupItem class="flex-column align-items-start border-bottom-0" v-if="!isMultiEvents">
        <div class="d-flex w-100 justify-content-between">
          <span>{{ isActivation ? $gettext('CR/Activations') : $gettext('CR/Check-ins') }}</span>
          <CBadge class="badge-chart font-weight-bold" shape="pill">{{
              checkinsConversionRate|number
            }}%</CBadge>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start border-bottom-0">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>Total orders</span>
          <span class="font-weight-bold">{{ data.total_orders|number }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start border-bottom-0" v-if="isResort">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>Upsell after stay</span>
          <span class="font-weight-bold">{{
              data.total_upsell_after_external|number
            }}</span>
        </div>
      </CListGroupItem>


      <CListGroupItem class="flex-column align-items-start border-bottom-0"
                      v-if="boughtPhotosData">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>Sold photos</span>
          <span class="font-weight-bold">{{
              boughtPhotosData.bought_photos_count|number
            }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start border-bottom-0">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>% Complete albums</span>
          <span class="font-weight-bold">{{ completePackages | number }}%</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start border-bottom-0">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>Average purchase</span>
          <span class="font-weight-bold">{{
              averagePurchase|currency(currency)
            }}</span>
        </div>
      </CListGroupItem>
      <template v-if="isResort">
        <CListGroupItem class="flex-column align-items-start border-bottom-0"
                        v-if="data && data.avg_purchase_during">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate>Average purchase during stay</span>
            <span class="font-weight-bold">{{
                data.avg_purchase_during|currency(currency)
              }}</span>
          </div>
        </CListGroupItem>
        <CListGroupItem class="flex-column align-items-start border-bottom-0"
                        v-if="data && data.avg_purchase_after">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate>Average purchase after stay</span>
            <span class="font-weight-bold">{{
                data.avg_purchase_after|currency(currency)
              }}</span>
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>
  </div>
</template>
<script>
import {industriesKeys} from "@/views_pica/stats/constants";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";

export default {
  name: "OrdersSummary",
  components: {ViewLoadingMessage},
  props: {
    data: {
      type: Object,
      required: false
    },
    boughtPhotosData: {
      type: Object,
      required: false
    },
    industry: {
      type: String,
      required: false
    },
    isMultiEvents: {
      type: Boolean,
      required: false,
      default: false
    },
    isActivation: {
      type: Boolean,
      required: false,
      default: false
    },
    currency: {
      type: String,
      required: false
    },
  },
  computed: {
    loading() {
      return !this.data
    },
    isResort() {
      return !this.isMultiEvents && this.industry === industriesKeys.RESORT
    },
    checkinsConversionRate() {
      return this.data.conversion_rate * 100
    },
    averagePurchase() {
      return (this.data.total_amount / this.data.total_orders) || 0
    },
    completePackages() {
      return ((this.data.total_orders_package / this.data.total_orders) * 100) || 0
    }
  },

}
</script>


<style scoped>

</style>