<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-0'" :loading="loading" :size="`sm`"/>
    <CListGroup flush class="list-striped list-striped--even" v-if="data">
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex">
            <h6 class="m-0">{{ isActivation ? $gettext('Total activations') : $gettext('Total check-ins') }}</h6>
            <PTooltip :content="tooltipContent.activationTotal" :color="tooltipColor" placement="left" align="top"/>
          </div>
          <CBadge class="badge-chart font-weight-bold" shape="pill">
            {{ data.total_count|number }}</CBadge>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start border-bottom-0"
                      v-if="photographicProductionData && validMedias">
        <div class="d-flex w-100 justify-content-between">
          <span v-translate>% Tagged media</span>
          <span class="font-weight-bold">{{ validMedias | number }}%</span>
        </div>
      </CListGroupItem>

      <template v-if="isResort">
        <CListGroupItem class="flex-column align-items-start border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <span>{{
                isActivation ? $gettext('Activations attendance today') : $gettext('Check-ins attendance today')
              }}</span>
            <span class="font-weight-bold">{{
                data.today_staying_count|number
              }}</span>
          </div>
        </CListGroupItem>
        <CListGroupItem class="flex-column align-items-start border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate>Average person stay</span>
            <span class="font-weight-bold">{{
                data.avg_days|number
              }}</span>
          </div>
        </CListGroupItem>
        <CListGroupItem class="flex-column align-items-start border-bottom-0">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate>Average family size</span>
            <span class="font-weight-bold">{{
                data.avg_family_size|number
              }}</span>
          </div>
        </CListGroupItem>
      </template>
      <template v-else>
        <CListGroupItem class="flex-column align-items-start border-bottom-0" :key="index"
                        v-for="(item, index) in totalsAsTable">
          <div class="d-flex w-100 justify-content-between">
            <span>{{ item.label }}</span>

            <span class="font-weight-bold">
                            {{ item.count }}
                          </span>
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>
  </div>
</template>
<script>
import {industriesKeys} from "@/views_pica/stats/constants";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipColor, tooltipContent} from "@/domain/eventMetric/tooltipContent";

export default {
  name: "CheckinsGroupBy",
  components: {PTooltip, ViewLoadingMessage},
  props: {
    data: {
      type: Object,
      required: false
    },
    dataTotals: {
      type: Array,
      required: false
    },
    industry: {
      type: String,
      required: false
    },
    isMultiEvents: {
      type: Boolean,
      required: false,
      default: false
    },
    isActivation: {
      type: Boolean,
      required: false,
      default: false
    },
    photographicProductionData: {
      type: Object,
      required: false
    },
  },
  computed: {
    loading() {
      return !this.data
          || !this.dataTotals
    },
    tooltipContent() {
      return tooltipContent
    },
    tooltipColor() {
      return tooltipColor
    },
    isResort() {
      return !this.isMultiEvents && this.industry === industriesKeys.RESORT
    },
    totalsAsTable() {
      let table = []
      let dataCleaned = []
      this.dataTotals.forEach((value) => {
        value.counts.forEach((item) => {
          dataCleaned.push(item)
        })
      })
      table = dataCleaned.reduce((accumulator, currentValue, i) => {
        const item = i > 0 && accumulator.find(({label}) => label === currentValue.label)
        if (item) item.count += currentValue.count;
        else accumulator.push({
          label: currentValue.label,
          count: currentValue.count
        });
        return accumulator;
      }, [])

      // exclude zero count
      table = table.filter(x => x.count > 0)
      // sort desc
      table.sort((a, b) => b.count - a.count)

      return table
    },
    validMedias() {
      if (this.photographicProductionData && this.photographicProductionData.valid_media_count) {
        return ((this.photographicProductionData.valid_media_count / this.photographicProductionData.total_count) * 100) || 0
      } else {
        return null
      }
    }
  },

}
</script>


<style scoped>

</style>