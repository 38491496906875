<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <Bar v-if="!loading"
         :chart-data="chartData"
         :chart-options="chartData.options"
    />
  </div>
</template>

<script>
import ChartHelper from "@/domain/core/utils/chart/ChartHelper";
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {chartPlugins} from "@/domain/core/utils/chart/_chartPlugins";
import Palette from "@/domain/core/utils/chart/Palette";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import ChartOptions from "@/domain/core/utils/chart/ChartOptions";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'MediaViewByPeriod',
  components: {ViewLoadingMessage, Bar},
  props: {
    photo_viewed: {
      type: Object,
      required: false
    },
    video_viewed: {
      type: Object,
      required: false
    },
    photo_opened: {
      type: Object,
      required: false
    },
    video_opened: {
      type: Object,
      required: false
    },
    label_format: {
      type: String,
      required: false,
    }
  },
  computed: {
    loading() {
      return !this.photo_viewed
          || !this.video_viewed
          || !this.photo_opened
          || !this.video_opened
    },
    chartData() {
      const chartHelper = new ChartHelper()
      chartHelper.setPalette(Palette.fuchsia)
      chartHelper.excludeEmpty()
      chartHelper.sortByNumber()

      if (this.label_format)
        chartHelper.setLabelFormat(this.label_format)

      chartHelper.addStats(this.photo_viewed, this.$gettext('Viewed photos'), 'Viewed')
      chartHelper.addStats(this.video_viewed, this.$gettext('Viewed videos'), 'Viewed')

      chartHelper.addStats(this.photo_opened, this.$gettext('Opened photos'), 'Opened')
      chartHelper.addStats(this.video_opened, this.$gettext('Opened videos'), 'Opened')

      let chartOptions = new ChartOptions()
      chartOptions.setTooltipCallbacks({
        footer: this.getTooltipFooter
      })
      chartOptions.enableLegend(chartHelper.getNumDatasets() > 1)

      return {
        labels: chartHelper.getXLabels(),
        datasets: chartHelper.getDatasets(),
        options: chartOptions.getOptions()
      }
    }
  },
   methods: {
    getTooltipFooter(context) {
      let sum = 0;
      context.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y
      })
      return `${this.$gettext('Total')}: ${this.$options.filters.number(sum)}`
    }
  }
}
</script>
