<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <Bar
        :chart-data="chartData"
        :chart-options="chartData.options"
        v-if="!loading"
    />
  </div>
</template>

<script>
import ChartHelper from "@/domain/core/utils/chart/ChartHelper";
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {chartPlugins} from "@/domain/core/utils/chart/_chartPlugins";
import Palette from "@/domain/core/utils/chart/Palette";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {fa} from "@faker-js/faker";
import ChartOptions from "@/domain/core/utils/chart/ChartOptions";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'ShareByPeriod',
  components: {ViewLoadingMessage, Bar},
  props: {
    instagram: {
      type: Object,
      required: false
    },
    facebook: {
      type: Object,
      required: false
    },
    whatsapp: {
      type: Object,
      required: false
    },
    twitter: {
      type: Object,
      required: false
    },
    telegram: {
      type: Object,
      required: false
    },
    linkedin: {
      type: Object,
      required: false
    },
    email: {
      type: Object,
      required: false
    },
    download: {
      type: Object,
      required: false
    },
    label_format: {
      type: String,
      required: false
    }
  },
  computed: {
    loading() {
      return !this.instagram
        || !this.facebook
        || !this.facebook
        || !this.twitter
        || !this.telegram
        || !this.linkedin
        || !this.email
        || !this.download
    },
    chartData() {
      const chartHelper = new ChartHelper()
      chartHelper.setPalette(Palette.fuchsia)
      chartHelper.excludeEmpty()
      chartHelper.sortByNumber()

      if (this.label_format)
        chartHelper.setLabelFormat(this.label_format)

      chartHelper.addStats(this.instagram, 'Instagram')
      chartHelper.addStats(this.facebook, 'Facebook')
      chartHelper.addStats(this.whatsapp, 'WhatsApp')
      chartHelper.addStats(this.twitter, 'X')
      chartHelper.addStats(this.telegram, 'Telegram')
      chartHelper.addStats(this.linkedin, 'LinkedIn')
      chartHelper.addStats(this.email, 'Email')
      chartHelper.addStats(this.download, 'Download')

      let chartOptions = new ChartOptions()
      chartOptions.setTooltipCallbacks({
        footer: this.getTooltipFooter
      })
      chartOptions.enableLegend(chartHelper.getNumDatasets() > 1)

      return {
        labels: chartHelper.getXLabels(),
        datasets: chartHelper.getDatasets(),
        options: chartOptions.getOptions()
      }
    }
  },
  methods: {
    getTooltipFooter(context) {
      let sum = 0;
      context.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y
      })
      return `${this.$gettext('Total')}: ${this.$options.filters.number(sum)}`
    }
  }
}
</script>
