<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <CListGroup flush class="list-striped list-striped--even" v-if="!loading">
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h6 v-translate>Click through rate</h6>
          <h6 class="badge badge-chart badge-pill font-weight-bold"
              style="font-size: 15px">{{ ratio }}
          </h6>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span v-translate>Banner views</span>
          <span class="font-weight-bold">{{ total_views|number }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span v-translate>Banners clicks</span>
          <span class="font-weight-bold">{{ total_click|number }}</span>
        </div>
      </CListGroupItem>

    </CListGroup>
  </div>
</template>

<script>
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";

export default {
  name: 'TotalBannerInteractions',
  components: {ViewLoadingMessage},
  props: {
    banner_viewed: {
      type: Object,
      required: false
    },
    banner_clicked: {
      type: Object,
      required: false
    },
  },
  computed: {
    loading() {
      return !this.banner_viewed
          || !this.banner_clicked
    },
    total_views() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.banner_viewed)
    },
    total_click() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.banner_clicked)
    },
    ratio() {
      if (this.total_views <= 0) return 'N/A'
      const percent = this.total_click / this.total_views
      return `${this.$options.filters.number(percent * 100)}%`
    }
  }
}
</script>
