<template>
  <CRow>
    <CCol>
      <CListGroup flush class="list-striped" v-if="items">
        <CListGroupItem v-for="(item, key) in items"
                        class="flex-column align-items-start border-bottom-0"
                        :key="key">
          <div class="d-flex w-100 justify-content-between">
            <span>{{ item.label }}</span>
            <div>
              <span class="font-weight-bold" v-if="mainTotal">{{ getPercentage(mainTotal, item.count)|number }}%</span>
              <span class="ml-2">({{ item.count }})</span>
            </div>
          </div>
        </CListGroupItem>
        <CListGroupItem class="flex-column align-items-start border-bottom-0" v-if="hasNun">
          <div class="d-flex w-100 justify-content-between">
            <span v-translate class="font-weight-semi-bold">Total</span>
            <div>
              <span class="font-weight-bold">{{ sumCountXNum|number }}</span>
            </div>
          </div>
          </CListGroupItem>
      </CListGroup>
    </CCol>
  </CRow>
</template>

<script>


import {getPercentage} from "@/utils/functions";

export default {
  name: "WidgetList",
  methods: {getPercentage},
  components: {},
  props: {
    total: {
      type: Number,
      required: false
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mainTotal() {
      return this.total ? this.total : this.autoTotal
    },
    autoTotal() {
      return this.items.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
    },
    hasNun() {
      return this.items.some(item => item.num !== undefined);
    },
    sumCountXNum() {
      if(!this.hasNun) return null;
      return this.items.reduce((accumulator, currentValue) => accumulator + currentValue.count * currentValue.num, 0);
    },
  }

}
</script>

<style scoped>

</style>