<template>
  <transition name="fade">
    <CCard>
      <CCardHeader class="border-bottom-0">
        <h5 class="my-2" v-translate>Breakdown by category</h5>
      </CCardHeader>
      <CCardBody class="p-0">

        <releases-data-table :pica-service-id="picaServiceId" :currency="currency" :in-card="true" />

      </CCardBody>
    </CCard>
  </transition>

</template>

<script>
import ReleasesDataTable from "@/views_pica/stats/releases/ReleasesDataTable.vue";

export default {
  name: "ReleasesCard",
  components: {ReleasesDataTable},

  props: {
    picaServiceId: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: false
    },
  }
}
</script>


<style scoped>

</style>