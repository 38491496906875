<template>
  <CRow>
    <CCol sm="6" lg="4" xl="3" class="mb-3" v-for="media in medias" :key="media.media_id">
      <h6>{{ media.media_id }} - {{ media.total }}</h6>
      <Bar
          :chart-data="media.graph"
          :chart-options="chartOptions"
      />
    </CCol>
  </CRow>
</template>
<script>
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {SHARE_TYPES} from "@/domain/eventMetric/consts";
import Palette from "@/domain/core/utils/chart/Palette";
import {chartPlugins} from "@/domain/core/utils/chart/_chartPlugins";


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'MostSharedMedia',
  components: {Bar},
  props: {
    data: {
      type: Array,
      required: true
    },
  },
  computed: {
    medias() {
      return this.data.map((item) => {
        return {
          media_id: item.media_id,
          total: item.Total,
          graph: this.generateGraph(item)
        }
      })
    }, chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {stacked: true, max: this.maxNumber},
          y: {stacked: true}
        },
        ...chartPlugins
      }
    }, columns() {
      return [
        {
          label: 'Instagram',
          code: SHARE_TYPES.INSTAGRAM,
          color: Palette.fuchsia[0]
        },
        {
          label: 'Facebook',
          code: SHARE_TYPES.FACEBOOK,
          color: Palette.fuchsia[1]
        },
        {
          label: 'WhatsApp',
          code: SHARE_TYPES.WHATSAPP,
          color: Palette.fuchsia[2]
        },
        {
          label: 'X',
          code: SHARE_TYPES.TWITTER,
          color: Palette.fuchsia[3]
        },
        {
          label: 'Telegram',
          code: SHARE_TYPES.TELEGRAM,
          color: Palette.fuchsia[4]
        },
        {
          label: 'Linkedin',
          code: SHARE_TYPES.LINKEDIN,
          color: Palette.fuchsia[5]
        },
        {
          label: 'E-Mail',
          code: SHARE_TYPES.EMAIL,
          color: Palette.fuchsia[6]
        },
        {
          label: 'Download',
          code: 'downloaded',
          color: Palette.fuchsia[7]
        },
      ]
    }, maxNumber() {
      return Math.max(...this.data.map((item) => item.Total))
    }
  },
  methods: {
    generateGraph(item) {
      return {
        labels: ['',],
        datasets: this.columns.map((column) => {
          return {
            label: column.label,
            data: [item[column.code]],
            backgroundColor: column.color,
          }
        })
      }
    }
  }
}
</script>
