<template>

  <transition name="fade">

    <CCard class="chart-card chart-card--blue" v-if="canViewStatsOrdersTransacted">
      <CCardHeader>
        <h5 class="my-2" v-translate>Orders and transactions</h5>
      </CCardHeader>
      <CCardBody class="pb-0">
        <ViewLoadingMessage :loading="!ordersCountByDate" class="pb-5"/>
        <template v-if="ordersCountByDate">
          <CRow>
            <CCol xl="8" class="mb-5">
              <div class="d-flex align-items-center justify-content-lg-center mb-4">
                <h5 class="font-weight-semi-bold mr-3 mb-0 chart-pills__label" v-translate>
                  Orders
                  by</h5>

                <CountBy :active="periodEnabled"
                         :count-by="currentPeriod"
                         @change="selectPeriod"/>
              </div>
              <div>
                <OrdersChart :chart-data-raw="ordersCountByDate" :currency="eventCurrency"
                             :label_format="selectedPeriod.format"
                             v-if="ordersCountByDate"/>
              </div>

            </CCol>
            <CCol xl="4" class="mb-5">
              <OrdersSummary :industry="industry"
                             :is-activation="isActivation"
                             :bought-photos-data="boughtPhotos"
                             :currency="eventCurrency"
                             :is-multi-events="isMultiEvents"
                             :data="ordersSummary"/>
            </CCol>
          </CRow>
          <CRow>
            <!-- BY STAY -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="ordersSummary && ordersSummary.by_stay && ordersSummary.by_stay.length">
              <ChartDoughnut :chart-data-raw="ordersSummary.by_stay"
                             :title="$gettext('Orders by stay')"
                             :total="ordersSummary.total_orders"/>
            </CCol>
            <!-- PAYMENT METHOD -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="ordersSummary && ordersSummary.by_method && ordersSummary.by_method.length">
              <ChartDoughnut :chart-data-raw="ordersSummary.by_method"
                             :title="$gettext('Orders by payment method')"
                             :total="ordersSummary.total_orders"/>
            </CCol>
            <!-- PAYMENT PLATFORM -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="ordersSummary && ordersSummary.by_platform && ordersSummary.by_platform.length">
              <ChartDoughnut :chart-data-raw="ordersSummary.by_platform"
                             :title="$gettext('Orders by platform')"
                             :total="ordersSummary.total_orders"/>
            </CCol>

            <!-- BY SOURCE: not for endurance -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="!isEndurance && ordersSummary && ordersByCampaign && ordersByCampaign.by_campaign && ordersByCampaign.by_campaign.length">
              <ChartDoughnut :chart-data-raw="ordersByCampaign.by_campaign"
                             :title="$gettext('Orders by campaign')"
                             :total="ordersSummary.total_orders"/>

            </CCol>

            <!-- CAMPAIGNS for ENDURANCE -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="isEndurance && ordersByCampaign">
              <WidgetCard :card-body-class="['px-0', 'pt-0']"
                                 :heading="$gettext('Orders by campaign')"
                                 class="flex-grow-1"
                                 v-if="ordersByCampaign">
                <orders-campaigns :data="ordersByCampaign" :currency="eventCurrency"/>
              </WidgetCard>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <ReleasesCard v-if="showReleases"
                            :pica-service-id="picaServiceId"
                            :currency="eventCurrency"/>
            </CCol>
          </CRow>
        </template>
      </CCardBody>
    </CCard>
  </transition>
</template>


<script>
import OrdersCampaigns from "@/views_pica/stats/orders/OrdersCampaigns.vue";
import OrdersSummary from "@/views_pica/stats/orders/OrdersSummary.vue";
import ChartDoughnut from "@/views_pica/stats/ChartDoughnut.vue";
import CountBy from "@/domain/eventMetric/components/CountBy.vue";
import ReleasesCard from "@/views_pica/stats/releases/ReleasesCard.vue";
import OrdersChart from "@/views_pica/stats/orders/OrdersChart.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import WidgetCard from "@/views_pica/stats/components/WidgetCard.vue";
import {mapActions, mapGetters} from "vuex";
import {period} from "@/domain/eventMetric/consts";

export default {
  name: "OrdersCard",
  components: {
    WidgetCard,
    ViewLoadingMessage,
    OrdersChart,
    ReleasesCard,
    CountBy,
    ChartDoughnut,
    OrdersSummary,
    OrdersCampaigns
  },
  data() {
    return {
      selectedPeriod: null,
    }
  },
  props: {
    industry: {
      type: String,
      required: false
    },
    isActivation: {
      type: Boolean,
      required: true
    },
    isEndurance: {
      type: Boolean,
      required: true
    },
    isMultiEvents: {
      type: Boolean,
      default: false,
    },
    picaServiceId: {
      type: Number,
      required: false
    },
    showReleases: {
      type: Boolean,
      required: true
    },
    isPromoEvent: {
      type: Boolean,
      required: false,
    },
    getParams: {
      type: Function,
      required: true
    },
    getBodyParams: {
      type: Function,
      required: true
    },
    periodEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    isDatasetTooOlder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("stats", [
      "canViewStatsOrdersTransacted",
      "ordersCountByDate",
      "ordersSummary",
      "ordersByCampaign",
      "boughtPhotos",
      "eventCurrency",
    ]),
    period() {
      return period
    },
    loadOrdersStats() {
      return this.isMultiEvents || !this.isPromoEvent
    },
    currentPeriod() {
      return this.selectedPeriod || this.period.DAY
    },
  },
  created() {
    this.selectedPeriod = this.period.DAY
  },
  async mounted() {
    if (!this.isMultiEvents)
      await this.getData()

    if (this.isDatasetTooOlder && this.periodEnabled)
      this.selectedPeriod = this.period.MONTH
  },
  methods: {
    ...mapActions("stats", [
      'getOrdersSummary',
      'getOrdersCountByDate',
      'getBoughtPhotos',
      'getOrdersCampaigns',
    ]),
    async getData() {
      if (this.loadOrdersStats) {
        if (!this.isMultiEvents) {
          await this.fetchOrdersCampaigns(this.isEndurance)
        }

        await this.getBoughtPhotos({
          picaServiceId: this.picaServiceId,
          params: this.getParams(this.currentPeriod.suffix),
          bodyParams: this.getBodyParams()
        })

        await this.fetchOrdersCountByDate()

        await this.getOrdersSummary({
          picaServiceId: this.picaServiceId,
          params: this.getParams(this.currentPeriod.suffix),
          bodyParams: this.getBodyParams()
        })
      }
    },
    async fetchOrdersCampaigns(detailed = false) {
      let params = {
        'detailed': detailed,
        ...this.getParams(this.currentPeriod.suffix)
      }

      await this.getOrdersCampaigns({
        picaServiceId: this.picaServiceId,
        params: params,
        bodyParams: this.getBodyParams()
      })
    },
    async fetchOrdersCountByDate() {
      await this.getOrdersCountByDate({
        picaServiceId: this.picaServiceId,
        params: this.getParams(this.currentPeriod.suffix),
        bodyParams: this.getBodyParams()
      })
    },
    selectPeriod(period) {
      this.selectedPeriod = period
      this.fetchOrdersCountByDate()
    }
  },
}
</script>


<style scoped>

</style>