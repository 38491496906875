<template>
  <div>
    <ViewLoadingMessage :loading="loading" :extra-class="'py-5'" :size="`sm`"/>
    <CListGroup flush class="list-striped list-striped--even" v-if="!loading">
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <h6 v-translate class="m-0">Unique content promoters</h6>
            <div v-if="can_download">
              <CButton @click="$emit('download')" class="ml-2 btn-circle btn-outline-dark">
                <CIcon name="cipDownload"/>
              </CButton>
            </div>
          </div>
          <div class="d-flex align-items-center">

              <CBadge class="badge-chart badge-pill font-weight-bold" shape="pill"
                      style="font-size: 15px">{{ active_users.actual|number }}
              </CBadge>

          </div>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span v-translate>Viewed media</span>
          <span class="font-weight-bold">{{ media_views|number }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span v-translate>Shared media</span>
          <span class="font-weight-bold">{{ media_shared|number }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center indent-final">
          <span v-translate>Including downloads</span>
          <span>{{ total_media_downloaded|number }}</span>
        </div>
      </CListGroupItem>
      <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span v-translate>Content promoters download</span>
          <span class="font-weight-bold">{{ unique_media_downloaded|number }}</span>
        </div>
      </CListGroupItem>
    </CListGroup>
  </div>
</template>

<script>
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";

export default {
  name: 'ActiveUsers',
  components: {ViewLoadingMessage},
  emits: ['download'],
  props: {
    shared_link_created: {
      type: Object,
      required: false
    },
    shared_link_opened: {
      type: Object,
      required: false
    },
    media_downloaded: {
      type: Object,
      required: false
    },
    active_users: {
      type: Object,
      required: false
    },
    photo_viewed: {
      type: Object,
      required: false
    },
    video_viewed: {
      type: Object,
      required: false
    },
    unique_media_downloaded: {
      type: Number,
      required: false
    },
    can_download: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    loading() {
      return !this.active_users.actual
          || !this.shared_link_created
          || !this.shared_link_opened
          || !this.media_downloaded
          || !this.active_users
          || !this.video_viewed
          || !this.photo_viewed
          || !this.unique_media_downloaded
    },
    media_shared() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.shared_link_created) + totalHelper.getTotal(this.media_downloaded)
    },
    total_media_downloaded() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.media_downloaded)
    },
    media_views() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.photo_viewed) + totalHelper.getTotal(this.video_viewed)
    }
  }
}
</script>
