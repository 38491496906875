import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

class ChartDoughnutHelper {
    constructor() {
        this.palette = []
        this.total = 0

    }

    addStats(data) {
        if (!data || data.length === 0) {
            return {labels: [], datasets: []}
        }

        data = this._normalizeData(data)
        let legendItems = this._getLegendItems(data)

        let labels = data.map(value => value.label)
        let datasetsData = data.map(value => value.count)
        let sumValues = datasetsData.reduce((a, b) => a + b, 0)

        let datasets = {
            data: datasetsData,
            backgroundColor: this.palette,
            isEmpty: sumValues === 0
        }

        this._setColors(datasets, this.palette)
        let chartData = {labels: labels, datasets: [datasets], isEmpty: sumValues === 0}

        return {chartData, legendItems}
    }

    setPalette(palette) {
        this.palette = palette
    }

    setTotal(total) {
        this.total = total
    }

    _setColors(dataset, palette) {
        dataset.backgroundColor = palette.map(color => color);
    }

    _normalizeData(data) {
        if (!data) {
            return [];
        }

        return data.map(value => ({
            count: value['orders_count'] || value['order_item_count'] || value['count'] || 0,
            count_label:  value['count'] ? $gettext('Checkins') : $gettext('Orders'), // if value['count'] is defined, it means that data are from checkins
            count_2: value.medias_count,
            count_label_2: $gettext('Sold photos'),
            label: value.label,
            amount: value.amount
        }));
    }

    _getLegendItems(data) {
        if (!data) {
            return [];
        }
        return data.map((value, index) => ({
            label: value.label,
            count: value.count || 0,
            count_label: value.count_label,
            count_2: value.count_2,
            count_label_2: value.count_label_2,
            amount: value.amount,
            percentage: !isNaN(value.count) ? ((value.count * 100) / this.total) : null,
            percentage_2: !isNaN(value.count_2) ? ((value.count_2 * 100) / this.total) : null,
            color: this.palette[index % this.palette.length]
        }));
    }
}

export default ChartDoughnutHelper
