<template>
  <transition name="fade">
    <CCard class="chart-card chart-card--purple" v-if="canViewStatsCheckins">
      <CCardHeader>
        <div class="d-flex">
          <h5 class="my-2">{{ isActivation ? $gettext('Activations') : $gettext('Check-ins') }}</h5>
        </div>
      </CCardHeader>
      <CCardBody class="pb-0">
        <ViewLoadingMessage :loading="!checkinsCountByDate" class="pb-5"/>
        <template v-if="checkinsCountByDate">
          <CRow>
            <CCol xl="8" class="mb-5">
              <div class="d-flex align-items-center justify-content-lg-center mb-4">
                <h5 class="font-weight-semi-bold mr-3 mb-0 chart-pills__label">
                  {{ isActivation ? $gettext('Activations by') : $gettext('Check-ins by') }}
                </h5>

                <CountBy :active="periodEnabled || !isDatasetTooOlder"
                         :count-by="currentPeriod"
                         @change="selectPeriod"/>
              </div>

              <div>
                <CheckinsChart :currency="eventCurrency"
                               :chart-data-raw="checkinsCountByDate"
                               :label_format="selectedPeriod.format"
                               v-if="checkinsCountByDate"/>
              </div>
            </CCol>
            <CCol lg="6" xl="4" class="mb-5">
              <CheckinsGroupBy :data="checkinsGroupBy"
                               :photographic-production-data="photographicProductionSummary"
                               :is-activation="isActivation"
                               :is-multi-events="isMultiEvents"
                               :data-totals="checkinsCountByDate"
                               :industry="industry"/>
            </CCol>
          </CRow>
          <CRow>
            <!-- BY STAY -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="checkinsGroupBy && checkinsGroupBy.by_stay && checkinsGroupBy.by_stay.length">
              <ChartDoughnut :chart-data-raw="checkinsGroupBy.by_stay"
                             :palette="Palette.purple"
                             :title="$gettext('Activations by stay')"
                             :total="checkinsGroupBy.total_count"/>
            </CCol>

            <!-- By group size -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="checkinsGroupBy && checkinsGroupBy.by_family_size && checkinsGroupBy.by_family_size.length">
              <WidgetCard :card-body-class="['px-0']" class="flex-grow-1"
                                 :heading="$gettext('Activations by group')"
                                 :tooltip="tooltipContent.activationByGroup">
                <WidgetList :total="checkinsGroupBy.total_count"
                                   :items="checkinsGroupBy.by_family_size" />
              </WidgetCard>

            </CCol>

            <!-- By album size -->
            <CCol lg="6" xl class="align-items-stretch d-flex"
                  v-if="checkinsGroupBy && checkinsGroupBy.by_album_size && checkinsGroupBy.by_album_size.length">
              <WidgetCard :card-body-class="['px-0']" class="flex-grow-1"
                                 :tooltip="tooltipContent.activationByContent"
                                 :heading="isActivation?$gettext('Content per activation'):$gettext('Content per check-in')"
              >
                <WidgetList :total="checkinsGroupBy.total_count"
                                   :items="checkinsGroupBy.by_album_size" />
              </WidgetCard>

            </CCol>

            <CCol lg=6 :class="tagsColXl" class="align-items-stretch d-flex"
                  v-if="showCheckinsTags">
              <CheckinsTags :data="checkinsTagsSummary"/>
            </CCol>
          </CRow>
        </template>
      </CCardBody>
    </CCard>
  </transition>
</template>
<script>
import ChartDoughnut from "@/views_pica/stats/ChartDoughnut.vue";
import WidgetCard from "@/views_pica/stats/components/WidgetCard.vue";
import CountBy from "@/domain/eventMetric/components/CountBy.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import CheckinsTags from "@/views_pica/stats/checkins/CheckinsTags.vue";
import CheckinsGroupBy from "@/views_pica/stats/checkins/CheckinsGroupBy.vue";
import WidgetList from "@/views_pica/stats/components/WidgetList.vue";
import CheckinsChart from "@/views_pica/stats/checkins/CheckinsChart.vue";
import {mapActions, mapGetters} from "vuex";
import {tooltipContent} from "@/domain/eventMetric/tooltipContent";
import Palette from "@/domain/core/utils/chart/Palette";
import {period} from "@/domain/eventMetric/consts";

export default {
  name: "CheckinsCard",
  components: {
    CheckinsChart,
    WidgetList,
    CheckinsGroupBy,
    CheckinsTags,
    ViewLoadingMessage,
    CountBy,
    WidgetCard,
    ChartDoughnut
  },
  data() {
    return {
      selectedPeriod: null,
    }
  },
  props: {
    picaServiceId: {
      type: Number,
      required: false
    },
    isActivation: {
      type: Boolean,
      required: true
    },
    isMultiEvents: {
      type: Boolean,
      default: false
    },
    industry: {
      type: String,
      required: false
    },
    getParams: {
      type: Function,
      required: true
    },
    getBodyParams: {
      type: Function,
      required: true
    },
    periodEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    isDatasetTooOlder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('stats', [
      'eventCurrency',
      'checkinsCountByDate',
      'checkinsGroupBy',
      'checkinsTagsSummary',
      'photographicProductionSummary',
      'canViewStatsCheckins'
    ]),

    tooltipContent() {
      return tooltipContent
    },
    Palette() {
      return Palette
    },
    period() {
      return period
    },
    currentPeriod() {
      return this.selectedPeriod || this.period.DAY
    },
    showCheckinsTags() {
      return !(this.isMultiEvents || !this.checkinsGroupBy || !Object.hasOwn(this.checkinsTagsSummary, 'counts')
          || Object.keys(this.checkinsTagsSummary.counts).length === 0);
    },
        totalCheckinWidgets() {
      // return the number of checkins widgets that have data
      return [this.checkinsGroupBy.by_album_size, this.checkinsGroupBy.by_family_size, this.checkinsGroupBy.by_stay]
          .filter(data => data && data.length)
          .length
    },
    tagsColXl() {
      if (!this.tagsAreGrouped) return 'col-xl'
      return this.totalCheckinWidgets >= 3 ? 'col-xl-12' : 'col-xl'
    },
    tagsAreGrouped() {
      return Object.keys(this.checkinsTagsSummary.counts).every(key => key.includes(":"));
    },
  },
  created() {
    this.selectedPeriod = this.period.DAY
  },
  async mounted() {
    if (!this.isMultiEvents)
      await this.getData()

    if (this.isDatasetTooOlder && this.periodEnabled)
      this.selectedPeriod = this.period.MONTH
  },
  methods: {
    ...mapActions('stats', [
      'getCheckinsGroupBy',
      'getCheckinsSummary',
      'getCheckinsCountByDate',
      'getCheckinsTagsSummary',
    ]),
    async getData(){
      await this.fetchCheckinsCountByDate()

      await this.getCheckinsSummary({
        picaServiceId: this.picaServiceId,
        params: this.getParams(this.currentPeriod.suffix),
        bodyParams: this.getBodyParams()
      })

      await this.fetchCheckinsGroupBy()

      if (!this.isMultiEvents) await this.getCheckinsTagsSummary({
        picaServiceId: this.picaServiceId,
        params: this.getParams(this.currentPeriod.suffix)
      })
    },
    async fetchCheckinsCountByDate() {
      await this.getCheckinsCountByDate({
        picaServiceId: this.picaServiceId,
        params: this.getParams(this.currentPeriod.suffix),
        bodyParams: this.getBodyParams()
      })
    },
    async fetchCheckinsGroupBy() {
      let params = {
        'album_size_partition': 'short',
        ...this.getParams(this.currentPeriod.suffix)
      }
      await this.getCheckinsGroupBy({
        picaServiceId: this.picaServiceId,
        params: params,
        bodyParams: this.getBodyParams()
      })
    },

    selectPeriod(period) {
      this.selectedPeriod = period
      this.fetchCheckinsCountByDate()
    }
  }
}
</script>


<style scoped>

</style>