<template>
  <div class="kpi" :class="['kpi--' + palette.name]">
    <ViewLoadingMessage extra-class="mt-5" :loading="loading" :size="`sm`"/>
    <WidgetKpi :heading="isActivation ? $gettext('Activations'): $gettext('Check-ins')"
                            :heading-icon="`cipEnter`"
                            :palette="palette"
                            :tooltip="tooltipContent.activation"
                            :heading-value="checkinsSummary.total_count|number"
                            v-if="!loading">
      <CListGroup flush>
         <CListGroupItem
              class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0" v-if="checkinsSummary.staying_participants_count">
            <div class="d-flex w-100 justify-content-between">
              <span v-translate>Check-ins during stay</span>
              <span class="font-weight-bold">{{
                  checkinsSummary.staying_participants_count|number
                }}</span>
            </div>
          </CListGroupItem>

        <template v-if="isResort">
          <CListGroupItem v-if="ordersSummary && !isMultiEvents"
                          class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex align-items-start">
                <span>{{ isActivation ? $gettext('CR/Activations') : $gettext('CR/Check-ins') }}</span>
                <PTooltip :content="tooltipContent.activationRate" placement="right" :color="tooltipColor"/>
              </div>
              <span class="font-weight-bold">{{
                  checkinsConversionRate|number
                }}%</span>
            </div>
          </CListGroupItem>
          <CListGroupItem
              class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0">
            <div class="d-flex w-100 justify-content-between">
              <span v-translate>Activations attendance today</span>
              <span class="font-weight-bold">{{
                  checkinsSummary.today_staying_count|number
                }}</span>
            </div>
          </CListGroupItem>
        </template>
        <template v-else>
          <CListGroupItem class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0"
                          v-if="checkinsSummary">
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex align-items-start">
                           <span>{{
                               isActivation ? $gettext('Activations/Participants') : $gettext('Check-ins/Participants')
                             }}</span>
                <PTooltip :content="tooltipContent.activationRate" placement="right" :color="tooltipColor"/>
              </div>

              <span class="font-weight-bold">{{ getCheckinsParticipantsPercentage() }}</span>
            </div>
          </CListGroupItem>
          <CListGroupItem class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0"
                          v-if="checkinsSummary">
            <div class="d-flex w-100 justify-content-between">
              <span v-translate>Estimated participants</span>
              <span class="font-weight-bold">{{ checkinsSummary.participants_count|number }}</span>
            </div>
          </CListGroupItem>
        </template>
      </CListGroup>
    </WidgetKpi>
  </div>
</template>

<script>
import PTooltip from "@/domain/core/components/PTooltip.vue";
import WidgetKpi from "@/views_pica/stats/components/WidgetKpi.vue";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {tooltipColor, tooltipContent} from "@/domain/eventMetric/tooltipContent";
import Palette from "@/domain/core/utils/chart/Palette";
import {mapGetters} from "vuex";

export default {
  name: "CheckinsKpi",
  components: {ViewLoadingMessage, WidgetKpi, PTooltip},

  props: {

    isResort: {
      type: Boolean,
      default: false,
    },
    isMultiEvents: {
      type: Boolean,
      default: false,
    },
    isActivation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('stats', [
      'checkinsSummary',
      'checkinsConversionRate']),
    Palette() {
      return Palette
    },

    tooltipColor() {
      return tooltipColor
    },
    tooltipContent() {
      return tooltipContent
    },
    loading() {
      return Object.keys(this.checkinsSummary).length === 0 || this.checkinsSummary === null
    },
    palette() {
      return {
        name: 'purple',
        color: Palette.purple[0]
      }
    },
  },
  methods: {
    getCheckinsParticipantsPercentage() {
      const value = this.checkinsSummary.total_count * 100 / this.checkinsSummary.participants_count
      return (value && isFinite(value) && value > 0) ? `${this.$options.filters.number(value)}%` : 'N/A'
    },
  }
}
</script>


<style scoped>

</style>