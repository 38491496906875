import moment from "moment"

class ChartDataHelper {
    constructor() {
        this.palette = []
        this.excludeEmptyFlag = false
        this.labelFormat = null
    }

    addStats(data) {
        if (!data || data.length === 0) {
            return {labels: [], datasets: []}
        }

        const datasetLabels = this._extractDatasetLabels(data)
        const labels = this._mapLabelsBasedOnDate(data)
        let datasets = this._initializeDataset(datasetLabels)

        this._fillCompleteDatasetWithData(data, labels, datasets)

        if (this.excludeEmptyFlag) {
            datasets = this._filterEmptyDatasets(datasets)
        }

        this._sortDatasetsByCount(datasets)
        this._setColors(datasets, this.palette)

        return {labels: labels, datasets}
    }

    setPalette(palette) {
        this.palette = palette
    }

    excludeEmpty(value = true) {
        this.excludeEmptyFlag = value
    }

    setLabelFormat(format) {
        this.labelFormat = format
    }

    // Private methods
    _extractDatasetLabels(data) {
        return data[0].counts.map(countData => countData.label)
    }

    _mapLabelsBasedOnDate(formattedData) {
        return formattedData.map(dataItem => {
            const formattedDate = this._formatDate(dataItem)
            return moment(formattedDate, "YYYY-MM-DD").format(this.labelFormat)
        })
    }

    _formatDate(dataItem) {
        const day = dataItem.day ? dataItem.day.toString().padStart(2, '0') : '01'
        const month = dataItem.month ? dataItem.month.toString().padStart(2, '0') : '01'
        const year = dataItem.year.toString()
        return `${year}-${month}-${day}`
    }

    _initializeDataset(datasetLabels) {
        return datasetLabels.map(label => ({
            backgroundColor: null,
            label,
            data: []
        }))
    }

    _fillCompleteDatasetWithData(data, labels, completeDataset) {
        data.forEach((dataItem, index) => {
            const label = labels[index]

            for (const [datasetIndex, datasetItem] of completeDataset.entries()) {
                datasetItem.data.push({
                    y: dataItem.counts[datasetIndex].count,
                    x: label,
                    sum: dataItem.counts[datasetIndex].sum
                })
            }
        })
    }

    _filterEmptyDatasets(datasets) {
        return datasets.filter(dataset => {
            return dataset.data.reduce((acc, value) => acc + value.y, 0) > 0
        })
    }

    _sortDatasetsByCount(datasets) {
        datasets.sort((a, b) =>
            b.data.reduce((acc, value) => acc + value.y, 0) -
            a.data.reduce((acc, value) => acc + value.y, 0)
        )
    }

    _setColors(datasets, palette) {
        datasets.forEach((dataset, index) => {
            dataset.backgroundColor = palette[index % palette.length]
        })
    }
}

export default ChartDataHelper
