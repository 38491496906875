<template>
  <WidgetCard :card-body-class="['px-0']" class="flex-grow-1"
                     :heading="$gettext('Tags')"
                     :tooltip="tooltipContent.tags"
                     tooltip-placement="left"
  >
    <!-- Categorized tags -->
    <CContainer fluid v-if="isGrouped">
      <CRow>
        <CCol lg="12" xl v-for="(categoryItems, categoryType) in groupedCategories" :key="categoryType">
          <CListGroup flush class="list-striped indent-children position-relative" :class="{'has-children': categoryItems.length>1}">
            <CListGroupItem class="flex-column align-items-start border-bottom-0 font-weight-bold text-capitalize">
              {{ categoryType }}
            </CListGroupItem>
            <CListGroupItem v-for="(item, index) in categoryItems" :key="index"
                            class="flex-column align-items-start border-bottom-0"
            >
              <div class="d-flex w-100 justify-content-between pl-3">
                <span>{{ getLabel(item.item) }}</span>
                <div>
                  <span
                      class="font-weight-bold">{{
                      getPercentage(getTotalByCategory(categoryType), item.count)|number
                    }}%</span>
                  <span class="ml-2">({{ item.count }})</span>
                </div>
              </div>
            </CListGroupItem>
          </CListGroup>
        </CCol>
      </CRow>
    </CContainer>

    <!-- Flat tags -->
    <CListGroup flush class="list-striped" v-else>
      <CListGroupItem v-for="(item, index) in flatTags" :key="index"
                      class="flex-column align-items-start border-bottom-0"
      >
        <div class="d-flex w-100 justify-content-between">
          <span class="text-capitalize">{{ item.label }}</span>
          <div>
                  <span
                      class="font-weight-bold">{{ getPercentage(totalFlatTags, item.count)|number }}%</span>
            <span class="ml-2">({{ item.count }})</span>
          </div>
        </div>
      </CListGroupItem>
    </CListGroup>

  </WidgetCard>
</template>

<script>
import WidgetCard from "@/views_pica/stats/components/WidgetCard.vue";
import {tooltipContent} from "@/domain/eventMetric/tooltipContent";
import {getPercentage} from "@/utils/functions";


export default {
  name: "CheckinsTags",
  components: {WidgetCard},
  props: {
    data: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    tooltipContent() {
      return tooltipContent;
    },
    isGrouped() {
      return Object.keys(this.counts).every(key => key.includes(":"));
    },
    counts() {
      return this.data.counts;
    },
    groupedCategories() {
      const grouped = {};
      for (const key in this.counts) {
        const [categoryType, item] = key.split(":");
        if (!grouped[categoryType]) {
          grouped[categoryType] = [];
        }
        grouped[categoryType].push({item, count: this.counts[key]});
      }
      return grouped;
    },
    flatTags() {
      return Object.keys(this.counts).map((key) => [{'label': key, 'count': this.counts[key]}]).flat()
    },
    totalFlatTags() {
      return Object.values(this.counts).reduce((acc, item) => acc + item, 0);
    },
    labels() {
      return {
        'third_domain': this.$pgettext('stats','Third level domain'),
        'pica': this.$pgettext('stats','Pica'),
        'site': this.$pgettext('stats', 'Website'),
        'mobile': this.$pgettext('stats', 'Mobile'),
        'android': this.$pgettext('stats', 'Android'),
        'apple': this.$pgettext('stats','Apple'),
      }
    }
  },
  methods: {
    getPercentage,
    getTotalByCategory(categoryType) {
      return this.groupedCategories[categoryType].reduce((acc, item) => acc + item.count, 0);
    },
    getLabel(key) {
      return this.labels[key] || key;
    }

  }
}
</script>


<style scoped>

</style>