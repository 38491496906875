<template>
  <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
    <Bar v-if="!loading"
         :chart-data="chartData"
         :chart-options="chartData.options"
    />
  </div>
</template>

<script>
import ChartHelper from "@/domain/core/utils/chart/ChartHelper";
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {chartPlugins} from "@/domain/core/utils/chart/_chartPlugins";
import Palette from "@/domain/core/utils/chart/Palette";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import ChartOptions from "@/domain/core/utils/chart/ChartOptions";


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BannerViewedByPeriod',
  components: {ViewLoadingMessage, Bar},
  props: {
    banner_viewed: {
      type: Object,
      required: false
    },
    banner_clicked: {
      type: Object,
      required: false
    },
    label_format: {
      type: String,
      required: false,
    }
  },
  computed: {
    loading() {
      return !this.banner_viewed
          || !this.banner_clicked
    },
    chartData() {
      const chartHelper = new ChartHelper()
      chartHelper.setPalette(Palette.fuchsia)
      chartHelper.excludeEmpty()
      chartHelper.sortByNumber()

      if (this.label_format)
        chartHelper.setLabelFormat(this.label_format)

      chartHelper.addStats(this.banner_viewed, 'Banner Viewed', 'Viewed')
      chartHelper.addStats(this.banner_clicked, 'Banner Clicked', 'Clicked')

      let chartOptions = new ChartOptions()
      chartOptions.setTooltipCallbacks({
        footer: this.getTooltipFooter
      })

      chartOptions.enableLegend(chartHelper.getNumDatasets() > 1)

      return {
        labels: chartHelper.getXLabels(),
        datasets: chartHelper.getDatasets(),
        options: chartOptions.getOptions()
      }
    },
  },
  methods: {
    getTooltipFooter(context) {
      let sum = 0;
      context.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y
      })
      return `${this.$gettext('Total')}: ${this.$options.filters.number(sum)}`
    }
  }
}
</script>
