<template>
  <WidgetCard class="flex-grow-1" :heading="title">
    <WidgetDoughnut :chart-data="data.chartData"
                           :currency="eventCurrency"
                           :legend-items="data.legendItems"/>
  </WidgetCard>
</template>

<script>
import Palette from "@/domain/core/utils/chart/Palette";
import ChartDoughnutHelper from "@/views_pica/stats/ChartDoughnutHelper";
import WidgetDoughnut from "@/views_pica/stats/components/WidgetDoughnut.vue";
import WidgetCard from "@/views_pica/stats/components/WidgetCard.vue";
import {mapGetters} from "vuex";


export default {
  name: "ChartDoughnut",
  components: {WidgetCard, WidgetDoughnut},
  props: {
    title: {
      type: String,
      required: true,
    },
    chartDataRaw: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
    palette: {
      type: Array,
      required: false,
      default: () => Palette.blue
    }
  },
  computed: {
    ...mapGetters('stats', ['eventCurrency']),
    data() {
      const chartDataHelper = new ChartDoughnutHelper()
      chartDataHelper.setPalette(this.palette)
      chartDataHelper.setTotal(this.total)

      return chartDataHelper.addStats(this.chartDataRaw)
    }

  },
}
</script>


<style scoped>

</style>