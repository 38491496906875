<template>
    <div>
    <ViewLoadingMessage :extra-class="'py-5'" :loading="loading" :size="`sm`"/>
  <CListGroup flush class="list-striped list-striped--even" v-if="!loading">
    <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <h6 class="m-0" v-translate>Open Rate</h6>
        <CBadge class="badge-chart font-weight-bold" shape="pill"
            style="font-size: 15px">{{ ratio }}
        </CBadge>
      </div>
    </CListGroupItem>
    <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <span v-translate>Viewed media</span>
        <span class="font-weight-bold">{{ media_views|number }}</span>
      </div>
    </CListGroupItem>
    <CListGroupItem class="flex-column align-items-start mb-2 border-bottom-0">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <span v-translate>Opened media</span>
        <span class="font-weight-bold">{{ media_opens|number }}</span>
      </div>
    </CListGroupItem>

  </CListGroup>
    </div>
</template>

<script>
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";

export default {
  name: 'TotalMediaInteractions',
  components: {ViewLoadingMessage},
  props: {
    photo_viewed: {
      type: Object,
      required: false
    },
    video_viewed: {
      type: Object,
      required: false
    },
    photo_opened: {
      type: Object,
      required: false
    },
    video_opened: {
      type: Object,
      required: false
    },
  },
  computed: {
    loading() {
      return !this.photo_viewed
          || !this.video_viewed
          || !this.photo_opened
          || !this.video_opened
    },
    media_views() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.photo_viewed) + totalHelper.getTotal(this.video_viewed)
    },
    media_opens() {
      const totalHelper = new TotalHelper()
      return totalHelper.getTotal(this.photo_opened) + totalHelper.getTotal(this.video_opened)
    },
    ratio() {
      if (this.media_views <= 0) return 'N/A'
      const percent = this.media_opens / this.media_views
      return `${this.$options.filters.number(percent * 100)}%`
    }
  }
}
</script>
