<template>
  <div>
    <ViewLoadingMessage :loading="!tableDataPhotographicProduction" class="pb-5"/>
    <template v-if="tableDataPhotographicProduction">
      <CRow v-if="photographicProductionSummary">
        <CCol>
          <CRow class="my-3 justify-content-lg-between justify-content-center">
            <CCol lg="6" xl class="my-2"><span
                class="font-weight-semi-bold mr-2 "
                v-translate>Uploaded photos</span>
              <h6 class="d-inline text-highlighted">
                {{ photographicProductionSummary.total_count | number }}</h6>
            </CCol>
            <CCol lg="6" xl
                  class="my-2">
              <div>
                <span class="font-weight-semi-bold mr-2" v-translate>Delivered photos</span>
                <h6 class="d-inline text-highlighted">
                  {{ photographicProductionSummary.total_delivered_count|number }}
                </h6>
              </div>
            </CCol>

            <CCol lg="6" xl v-if="showImagoData"
                  class="my-2"><span class="font-weight-semi-bold mr-2"
                                     v-translate>Delivered photos (Imago)</span>
              <h6 class="d-inline text-highlighted">
                {{ photographicProductionSummary.imago_delivered_count|number }}
              </h6>
            </CCol>
            <CCol lg="12" xl class="text-right my-2" v-if="ordersSummary && !isPromoEvent">
              <div class="d-flex align-items-center justify-content-xl-between photo-prod__amount"
                   v-if="canViewStatsPhotographicAmountProduction">
                <h6 v-translate>Total amount</h6>
                <h6 class="badge badge-chart badge-pill font-weight-bold ml-2"
                    style="font-size: 15px">
                  {{ ordersSummary.total_amount|currency(eventCurrency) }}
                </h6>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol v-if="tableDataPhotographicProduction">
          <CDataTable v-for="(item, index) in tableDataPhotographicProduction" :key="index"
                      :items="[item]"
                      :header="false"
                      :fields="photographersDataFields"
                      class="custom-table mb-4">
            <template #email="{item}">
              <td style="width: 400px">
                <h6 class="font-weight-semi-bold pl-2">{{
                    item.photographer.email || $gettext('Photographer deleted')
                  }}</h6>
              </td>
            </template>
            <template #count="{item}">
              <td style="width: 300px">
                <span v-translate class="mr-1">Uploaded photos</span>
                <span class="font-weight-semi-bold">{{ item.count|number }}</span>
              </td>
            </template>
            <template #delivered_count="{item}">
              <td style="width: 300px">
                <span v-translate class="mr-1">Delivered photos</span>
                <span class="font-weight-semi-bold">{{
                    item.delivered_count|number
                  }}</span>
              </td>
            </template>
            <template #medias_count="{item}">
              <td style="width: 300px">
                <span v-translate class="mr-1">Sold photos</span>
                <span class="font-weight-semi-bold">
                          <template v-if="item.medias_count > -1">{{ item.medias_count|number }}</template>
                          <template v-else>N/A</template>
                        </span>
              </td>
            </template>
            <template #total_amount="{item}">
              <td style="text-align: right">
                <div v-if="canViewStatsPhotographicAmountProduction">
                  <CSpinner v-if="item.total_amount === -1" class="text-highlighted"
                            grow style="width:10px;height:10px;margin-bottom: 3px"/>
                  <h6 class="text-highlighted">
                    <template v-if="item.total_amount && item.total_amount > -1">
                      {{ item.total_amount|currency(eventCurrency) }}
                    </template>
                    <template v-else>N/A</template>
                  </h6>
                </div>

              </td>
            </template>
            <template #actions="{item}">
              <td style="width: 40px">
                <CButton :class="['btn-grid-toggle', {'on': item._toggled}]"
                         @click="togglePhotographerDetails(item)"/>
              </td>
            </template>
            <template #details="{ item }">
              <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                <div style="min-height: 100px"> <!-- necessary for slide anim -->
                  <template v-if="showPhotographerReleases">
                    <releases-data-table v-if="Boolean(item._toggled)" :currency="eventCurrency"
                                         :photographer-id="item.photographer.id"
                                         :pica-service-id="picaServiceId"/>
                  </template>
                  <template v-else>
                    <ViewLoadingMessage
                        :loading="!photographerDataDetail[item.photographer.id]"/>
                    <CCardBody class="p-0"
                               v-if="photographerDataDetail[item.photographer.id]">
                      <CDataTable
                          :striped="true"
                          :items="photographerDataDetail[item.photographer.id].results"
                          :fields="photographerDetailDataFields"
                          class="custom-table custom-table--clean custom-table--border-top custom-table--nested mb-4">

                        <template #created_at="{ item }">
                          <td>
                            {{ item.created_at|formatDateTime('l LT') }}
                          </td>
                        </template>
                        <template #count="{ item }">
                          <td> <span style="font-size: 15px">
                                       {{ getUploadPercentage(item)|number }}%</span>
                          </td>
                        </template>

                        <template #valid_photos="{ item }">
                          <td>
                            {{ item.count - item.invalid_count }}
                            <translate>of</translate>
                            {{ item.count }}
                            ({{ getValidPhotosPercentage(item)|number }}%)
                          </td>
                        </template>
                        <template #notes="{ item }">
                          <td>
                            <span v-if="item.notes">{{ item.notes }}</span>
                            <span v-else>----</span>
                          </td>
                        </template>
                        <template #recognition="{ item }">
                          <td>
                            <CIcon name="cip-check-circle" width="16"
                                   v-if="getRecognitionPercentage(item) === 100"/>
                            <CRow class="align-items-center" v-else>
                              <CCol>
                                <CProgress
                                    :value="getRecognitionPercentage(item)"
                                    size="sm"/>
                              </CCol>
                              <CCol><span>{{ getRecognitionPercentage(item)|number }}%</span>
                              </CCol>
                            </CRow>
                          </td>
                        </template>
                        <template #medias_original_uploaded="{ item }">
                          <td>
                            <CIcon :name="item.medias_original_uploaded ? 'cipDotGreen':'cipDotOrange'"
                                   size="custom-size" :height="10"/>
                          </td>
                        </template>
                      </CDataTable>

                    </CCardBody>
                    <CCardFooter v-if="photographerDataDetail[item.photographer.id]"
                                 v-show="photographerDataDetail[item.photographer.id].pagination.num_pages > 1">
                      <CPagination
                          @update:activePage="changePagePhotographerDetail(item.photographer.id, $event)"
                          :active-page.sync="photographerDataDetail[item.photographer.id].pagination.page_number "
                          :pages="photographerDataDetail[item.photographer.id].pagination.num_pages "/>
                    </CCardFooter>

                  </template>

                </div>
              </CCollapse>

            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </template>
  </div>
</template>
<script>
import StatsService from "@/services/stats.service";
import {mapActions, mapGetters} from "vuex";
import {photographerDetailDataFields, photographersDataFields} from "@/views_pica/stats/constants";
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import ReleasesDataTable from "@/views_pica/stats/releases/ReleasesDataTable.vue";

export default {
  name: "PhotographicProductionDataTable",
  components: {ReleasesDataTable, ViewLoadingMessage},
  emits: ['canView'],
  data() {

    return {
      tableDataPhotographicProduction: null,
      photographerDataDetail: {},
      collapseDuration: 300,
    }
  },
  props: {
    isMultiEvents: {
      type: Boolean,
      default: false
    },
/*    loadStats: {
      type: Boolean,
      default: false
    },*/
    selectedPeriod: {
      type: Object,
      required: true,
      default: null
    },
    getParams: {
      type: Function,
      required: true
    },
    getBodyParams: {
      type: Function,
      required: true
    },
    photographicProductionSummary: {
      type: Object,
      default: null
    },
    showImagoData: {
      type: Boolean,
      default: false
    },

  },
  computed: {
    ...mapGetters('event', [
      'usesFaceRecognition',
      'picaServiceId',
      'industry',
      'isPromo'
    ]),
    ...mapGetters('stats', [
      'ordersSummary',
      'eventCurrency',
      'ordersAmountByPhotographer',
      'canViewStatsPhotographicAmountProduction',
      'uploadsCountByPhotographer',
      'uploadsCountByUploadTask'
    ]),
    isPromoEvent() {
      return !this.isMultiEvents && this.isPromo
    },
    photographersDataFields: {
      get() {
        let fields = Object.values(photographersDataFields)
        if (this.isPromoEvent) {
          fields = fields.filter(x => x.key !== 'medias_count')
        }
        return fields
      },
      set(val) {
        return val
      }
    },
    photographerDetailDataFields: {
      get() {
        return Object.values(photographerDetailDataFields)
      },
      set(val) {
        return val
      }
    },
    showPhotographerReleases() {
      return !this.isMultiEvents && !this.usesFaceRecognition
    },
  },
  mounted() {
    if (!this.isMultiEvents) this.getPhotographicProductionData()
  },
  methods: {
    ...mapActions('stats', [
      'getOrdersAmountByPhotographer',
      'getUploadsCountByPhotographer',
      'getUploadsCountByUploadTask'
    ]),
    togglePhotographerDetails(item) {
      if (!item._toggled) {
        if (this.showPhotographerReleases) {

        } else {
          this.getPhotographerDetail(item.photographer.id)
        }

      }

      this.$set(item, "_toggled", !item._toggled)

      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },
    // PHOTOGRAPHIC PRODUCTION
    async fetchPhotographerDetail(photographerId, args) {
      const params = {
        ...this.getParams(this.selectedPeriod.suffix),
        ...{photographer: photographerId},
        ...args
      }
      return await this.getUploadsCountByUploadTask({
        picaServiceId: this.picaServiceId,
        params: params,
        bodyParams: this.getBodyParams()
      });
    },

    notesExist(data) {
      return data.results.some(item => item.notes);
    },

    setPhotographerDetailData(photographerId, data) {
      this.$set(this.photographerDataDetail, photographerId, data);
    },

    async getPhotographerDetail(photographerId, args) {
      let data = await this.fetchPhotographerDetail(photographerId, args);
      let hasNotes = this.notesExist(data);
      if (!hasNotes) {
        this.photographerDetailDataFields = this.photographerDetailDataFields.filter(x => x.key !== 'notes');
      }
      this.setPhotographerDetailData(photographerId, data);
    },

    async getPhotographicProductionData() {
      //if (!this.loadStats) return
      try {
        await this.getUploadsCountByPhotographer({
          picaServiceId: this.picaServiceId,
          params: this.getParams(this.selectedPeriod.suffix),
          bodyParams: this.getBodyParams()
        })

        // move to store ?
        this.$emit('canView', true)
      } catch (error) {
        if (error.response) this.$emit('canView', error.response.status !== 403)
      }

      // display ready data... then will appear others below
      if (!this.uploadsCountByPhotographer) return

      for (const [index, value] of this.uploadsCountByPhotographer.by_photographer.entries()) {
        value.total_amount = -1
        value.medias_count = -1
      }

      if (this.isMultiEvents) {
        this.$set(this, 'tableDataPhotographicProduction', this.uploadsCountByPhotographer.by_photographer)
      }

      try {
        await this.getOrdersAmountByPhotographer({
          picaServiceId: this.picaServiceId,
          params: this.getParams(this.selectedPeriod.suffix),
          bodyParams: this.getBodyParams()
        })


        for (const [index, value] of this.uploadsCountByPhotographer.by_photographer.entries()) {
          const photographerId = value.photographer.id
          const photographer = this.ordersAmountByPhotographer.by_photographer.find(x => x.photographer_id === photographerId)
          value.total_amount = photographer ? photographer.amount : 0
          value.medias_count = photographer ? photographer.medias_count : 0
          // need this prop to filter by photographerId
          value.photographer_id = photographerId
        }

      } catch (error) {
        // this.canViewStatsPhotographicAmountProduction = false
      } finally {
        this.$set(this, 'tableDataPhotographicProduction', this.uploadsCountByPhotographer.by_photographer)
      }
    },
    getValidPhotosPercentage(item) {
      return (100 - (item.invalid_count * 100) / item.count) || 0
    },
    getRecognitionPercentage(item) {
      return ((item.recognition_count * 100) / item.count) || 0
    },

    getUploadPercentage(item) {
      let photographer = this.tableDataPhotographicProduction.find(x => x.photographer_id === item.photographer_id)
      return photographer ? ((item.count * 100) / photographer.count) || 0 : 0
    },
    changePagePhotographerDetail(photographerId, page) {
      this.getPhotographerDetail(photographerId, {page: page})
    },
  },
}
</script>


<style scoped>

</style>