export const chartPlugins = {
    plugins: {
        title: {
            display: false,
        },
        tooltip: {
            padding: 12,
            usePointStyle: true,
            boxPadding: 5,
            boxHeight: 12,
            boxWidth: 12,
            backgroundColor: '#dcdbdb',
            titleMarginBottom: 10,
            titleColor: '#100F23',
            titleFont: {
                size: 13,
                family: 'Inter, sans-serif'
            },
            bodySpacing: 8,
            bodyColor: '#100F23',
            bodyFont: {
                size: 13,
                family: 'Inter, sans-serif'
            },
            footerColor: '#100F23',
            footerMarginTop: 8,
            footerFont: {
                size: 13,
                weight: 500,
                family: 'Inter, sans-serif'
            },
            callbacks: {}

        },
        legend: {
            display: true,
            labels: {
                boxHeight: 12,
                boxWidth: 12,
                useBorderRadius: true,
                borderRadius: 6
            }
        },
    }
}