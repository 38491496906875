import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate
export const releasesDataFields = {
    'release_id': {
        key: 'release_id',
        label: $pgettext('stats', 'Release'),
    },
    'price_list_name': {
        key: 'price_list_name',
        label: $pgettext('stats', 'Price list'),
    },
    'checkin_quantity': {
        key: 'checkin_quantity',
        label:$pgettext('stats', 'Check-ins'),
        _style: ''
    },
     'recognized_participants': {
        key: 'recognized_participants',
        label: $pgettext('stats', 'Check-ins/Participants'),
        _style: ''
    },
    'order_conversion_rate': {
        key: 'order_conversion_rate',
        label:$pgettext('stats', 'CR'),
    },
    'order_count': {
        key: 'order_count',
        label:$pgettext('stats', 'Orders'),
        _style: ''
    },
    'order_amount': {
        key: 'order_amount',
        label:$pgettext('stats', 'Transacted amount'),
    },
}